import React, { useEffect, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@melloware/coloris/dist/coloris.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import Coloris from '@melloware/coloris'

import { Translation } from '../../../../helpers/Translation'
import {
  GALLERY_BG_DEFAULT,
  PREVIEW_BG_COLORS,
  PX_TO_REM,
  SELECTED_CLASS,
  TRANSPARENT_KEY,
} from '../../../../helpers/Constants'

import Tooltip from '../../../../components/Tooltip'

import { ReactComponent as OverlayBackgroundTransparentSvg } from '../../../../svg/overlay_background_transparent.svg'
import { ReactComponent as OverlayBackgroundAddSvg } from '../../../../svg/overlay_background_add.svg'
import { ReactComponent as OverlayBackgroundRemoveSvg } from '../../../../svg/overlay_background_remove.svg'

import './index.scss'

const Background = () => {
  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
  }))

  const orderActions = useStoreActions((actions) => ({
    setGalleryBackground: actions.order.setGalleryBackground,
    setPreviewsBackground: actions.order.setPreviewsBackground,
  }))

  const colorisGalleryBackground = useRef(null)
  const colorisPreviewBackground = useRef(null)

  useEffect(() => {
    const clrPicker = document.getElementById('clr-picker')
    if (!clrPicker) Coloris.init()
  }, [])

  const onRemove = () => {
  }

  const onGalleryBackgroundChange = (color) => {
    orderActions.setGalleryBackground(color)
  }

  const onPreviewBackgroundChange = (color) => {
    orderActions.setPreviewsBackground(color)
  }

  const onAddGalleryBackground = () => {
    Coloris({
      el: `#${colorisGalleryBackground.current.id}`,
      wrap: false,
      alpha: false,
      focusInput: false,
      defaultColor: orderState.galleryBackground !== TRANSPARENT_KEY ? orderState.galleryBackground : GALLERY_BG_DEFAULT,
      onChange: onGalleryBackgroundChange,
    })
    colorisGalleryBackground.current.click()
  }

  const onAddPreviewBackground = () => {
    Coloris({
      el: `#${colorisPreviewBackground.current.id}`,
      wrap: false,
      alpha: false,
      focusInput: false,
      defaultColor: orderState.previewsBackground !== TRANSPARENT_KEY ? orderState.previewsBackground : '#FFFFFF',
      onChange: onPreviewBackgroundChange,
    })
    colorisPreviewBackground.current.click()
  }

  const onSelectGalleryBackground = (color) => {
    if (color === TRANSPARENT_KEY) return // transparent background can not be applied to gallery
    orderActions.setGalleryBackground(color)
  }

  const onSelectPreviewBackground = (color) => {
    orderActions.setPreviewsBackground(color)
  }

  return (
    <div className="background-block-container">
      <div className="background-right">
        {orderState.gallery?.gallery_backgrounds.map((bg, index) => (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position="left"
            offset="150%"
            text={bg.name}
          >
            <div
              className={`color ${bg.color === orderState.galleryBackground ? SELECTED_CLASS : ''}`}
              style={{
                backgroundColor: bg.color,
                ...(bg.color === TRANSPARENT_KEY && { cursor: 'auto' }),
              }}
              onClick={() => onSelectGalleryBackground(bg.color)}
            >
              {bg.color === TRANSPARENT_KEY && <OverlayBackgroundTransparentSvg />}
              {bg.is_format_background && (
                <span
                  className="color--text"
                  style={{
                    color: (bg.is_light_color || bg.color === TRANSPARENT_KEY)
                      ? '#000000' : '#FFFFFF',
                  }}
                >
                  {Translation.format_short}
                  {bg.format_index}
                </span>
              )}
              {bg?.is_added_bg && (
                <OverlayBackgroundRemoveSvg className="remove" onClick={onRemove} />
              )}
            </div>
          </Tooltip>
        ))}

        <Tooltip
          position="left"
          offset="150%"
          text={Translation.add_background}
        >
          <div className="color empty" onClick={onAddGalleryBackground}>
            <OverlayBackgroundAddSvg />
            <input
              id="colorisGalleryBackground"
              ref={colorisGalleryBackground}
              type="text"
              className="coloris-input"
              style={{ top: `-${PX_TO_REM['14']}rem` }}
              value={orderState.galleryBackground !== TRANSPARENT_KEY ? orderState.galleryBackground : GALLERY_BG_DEFAULT}
              onChange={() => {
              }}
            />
          </div>
        </Tooltip>
      </div>

      <div className="background-right">
        <Tooltip
          position="left"
          offset="150%"
          text={TRANSPARENT_KEY.toUpperCase()}
        >
          <div
            className={`color ${orderState.previewsBackground === TRANSPARENT_KEY ? SELECTED_CLASS : ''}`}
            onClick={() => onSelectPreviewBackground(TRANSPARENT_KEY)}
          >
            <OverlayBackgroundTransparentSvg />
          </div>
        </Tooltip>

        {PREVIEW_BG_COLORS.map((bgColor, index) => (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position="left"
            offset="150%"
            text={bgColor}
          >
            <div
              className={`color ${bgColor === orderState.previewsBackground ? SELECTED_CLASS : ''}`}
              style={{ backgroundColor: bgColor }}
              onClick={() => onSelectPreviewBackground(bgColor)}
            >
              {bgColor?.is_added_bg && (
                <OverlayBackgroundRemoveSvg className="remove" onClick={onRemove} />
              )}
            </div>
          </Tooltip>
        ))}

        <Tooltip
          position="left"
          offset="150%"
          text={Translation.add_background}
        >
          <div className="color empty" onClick={onAddPreviewBackground}>
            <OverlayBackgroundAddSvg />
            <input
              id="colorisPreviewBackground"
              ref={colorisPreviewBackground}
              type="text"
              className="coloris-input"
              style={{ top: `-${PX_TO_REM['14']}rem` }}
              value={orderState.previewsBackground !== TRANSPARENT_KEY ? orderState.previewsBackground : '#FFFFFF'}
              onChange={() => {
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default Background
