import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import {
  ACTIVE_CLASS,
  ALL_LAYERS_KEY,
  DEFAULT_IS_EXPANDED,
  EMPTY_IMG,
  HIDE_DROPDOWN_TIMEOUT,
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  IMAGE_PREVIEW_MIN_HEIGHT,
  IMAGE_SIZES,
  IMAGE_STATUSES,
  IMAGE_THUMB_BORDER,
  IMAGE_TYPES,
  INTERVAL_1_SECOND,
  NOSELECT_CLASS,
  PATH_COLORS,
  PREVIEW_BG_CLASS,
  PREVIEW_CLASS,
  PREVIEW_COMMENT_DRAWING_CLASS,
  PREVIEW_GRID_CLASS,
  PREVIEW_NOT_FOUND_BG_COLOR,
  PX_TO_REM,
  SELECTABLE_CLASS,
  SELECTED_IMAGES_CLASS,
  SHOW_PATH_PREVIEW_CLASS,
  SKELETON_CLASS,
  SYSTEM_USER_ID,
  TEST_IMAGE_TYPE,
  TRANSPARENT_BG,
  TRANSPARENT_KEY,
} from '../../../../helpers/Constants'
import {
  brokenFilePreview,
  notFoundPreview,
  isBrokenImage,
  removeClass,
  dragElement,
  useEventListener,
  getEndpointUrl,
  removeAllChildren,
  isAltKey,
  buildGrids,
  buildPath,
  isAllImagesLoaded,
  parseFloatRound,
  setSvgSizeAndViewBox,
  appendShapes,
  getStrokeWidth,
  getPreviewWidth,
  localizeDate,
} from '../../../../helpers/Utils'
import { API_ENDPOINTS_GALLERY } from '../../../../helpers/Urls'

import Tooltip from '../../../../components/Tooltip'
import Checkbox from '../../../../components/Checkbox'
import Typography from '../../../../components/Typography'
import RadioGroup from '../../../../components/RadioGroup'
import ColorPicker from '../../../../components/ColorPicker'

import Comment from '../../Comment'

import { ReactComponent as PreviewCheckSvg } from '../../../../svg/preview_check.svg'
import { ReactComponent as PreviewUndoSvg } from '../../../../svg/preview_undo.svg'
import { ReactComponent as PreviewImageSvg } from '../../../../svg/preview_image.svg'
import { ReactComponent as PreviewDownloadSvg } from '../../../../svg/preview_download.svg'
import { ReactComponent as PreviewDuplicateSvg } from '../../../../svg/preview_duplicate.svg'
import { ReactComponent as PreviewMoreSvg } from '../../../../svg/preview_more.svg'
import { ReactComponent as PreviewSendSvg } from '../../../../svg/preview_send.svg'
import { ReactComponent as PreviewDeleteSvg } from '../../../../svg/preview_trash.svg'
import { ReactComponent as PreviewEditedBySvg } from '../../../../svg/preview_edited_by.svg'
import { ReactComponent as OverlayCloseIconSvg } from '../../../../svg/overlay_close_icon.svg'
import { ReactComponent as EyeSvg } from '../../../../svg/eye.svg'
import { ReactComponent as EyeDropperSvg } from '../../../../svg/action_eye_dropper.svg'
import { ReactComponent as ActionLikeSvg } from '../../../../svg/action_like.svg'
import { ReactComponent as ActionDislikeSvg } from '../../../../svg/action_dislike.svg'
import { ReactComponent as PathOutsideImageIconSvg } from '../../../../svg/path_outside_image.svg'
import { ReactComponent as LayerMaskIconSvg } from '../../../../svg/layer_mask.svg'
import { ReactComponent as LayerVisibleIconSvg } from '../../../../svg/layer_visible.svg'
import { ReactComponent as LayerInvisibleIconSvg } from '../../../../svg/layer_invisible.svg'

import './index.scss'

const getPreviewStyles = (previewWidth, previewHeight, imageTagColor) => {
  const width = parseFloatRound(previewWidth + (2 * IMAGE_THUMB_BORDER))
  const height = parseFloatRound(previewHeight + (2 * IMAGE_THUMB_BORDER))

  const imageWrapStyle = {
    width: `${(width)}px`,
    backgroundColor: imageTagColor,
  }

  const previewStyle = {
    width: `${previewWidth}px`,
    height: `${previewHeight}px`,
  }

  const margin = IMAGE_PREVIEW_MIN_HEIGHT > previewHeight
    ? `${parseFloatRound((IMAGE_PREVIEW_MIN_HEIGHT - previewHeight) / 2)}` : 0

  const previewBgStyle = {
    width: `${width}px`,
    height: `${height}px`,
    marginTop: `${margin}px`,
    marginBottom: `${margin}px`,
  }

  return [previewStyle, imageWrapStyle, previewBgStyle]
}

const getUnderlayStyle = (previewWidth, previewHeight, underlayWidth, underlayHeight) => {
  const ratioW = previewWidth / underlayWidth
  const ratioH = previewHeight / underlayHeight

  let ratio = (ratioW > ratioH) ? ratioH : ratioW
  if (ratio > 1) ratio = 1

  const width = parseFloatRound(underlayWidth * ratio)
  const height = parseFloatRound(underlayHeight * ratio)

  const left = previewWidth > width
    ? `${parseFloatRound((previewWidth - width) / 2)}` : 0
  const top = previewHeight > height
    ? `${parseFloatRound((previewHeight - height) / 2)}` : 0

  return {
    width: `${width}px`,
    height: `${height}px`,
    left: `${left}px`,
    top: `${top}px`,
  }
}

const getPreviewClass = (id, isUrlShown, status, commentsLength, selectedImages, isAdmin) => {
  const previewClass = isUrlShown ? '' : SKELETON_CLASS

  let previewWrapClass = 'gallery-image--wrap'
  if (commentsLength) previewWrapClass += ' gallery-image--wrap__error'

  const statusInt = parseInt(status, 10)
  if (statusInt === IMAGE_STATUSES.internalReference
    || statusInt === IMAGE_STATUSES.backgroundCode
    || statusInt === IMAGE_STATUSES.colorationReference
    || statusInt === IMAGE_STATUSES.colorReference
    || statusInt === IMAGE_STATUSES.inputSpReference
  ) previewWrapClass += ' gallery-image--wrap__reference'
  else if (statusInt === IMAGE_STATUSES.confirmed && isAdmin) previewWrapClass += ' gallery-image--wrap__confirmed'
  else if (statusInt === IMAGE_STATUSES.broken) previewWrapClass += ' gallery-image--wrap__broken'

  if (selectedImages.includes(id)) previewWrapClass += ` ${SELECTED_IMAGES_CLASS}`

  return [previewClass, previewWrapClass]
}

const getPreviewSrc = (image, links, isOriginalImage, imageSize, selectedLayer, layerUrls) => {
  let previewSrc = EMPTY_IMG

  if (isBrokenImage(image?.status)) previewSrc = brokenFilePreview
  else if (
    selectedLayer !== `${ALL_LAYERS_KEY}-${image?.id}`
    && image?.layer.some((l) => l.id === selectedLayer)
  ) {
    const layerId = image?.layer.find((l) => l.id === selectedLayer).id
    previewSrc = layerUrls?.[image?.id]?.[layerId]
  } else if (image?.is_url_shown) previewSrc = image?.url
  else if (isOriginalImage) previewSrc = links[image?.original_image_id]?.[imageSize]
  else if (links[image?.id]) previewSrc = links[image?.id]?.[imageSize]

  return previewSrc
}

const handlePreviewImgError = (e) => {
  const previewImg = e.currentTarget
  previewImg.onerror = null // prevents looping
  previewImg.src = notFoundPreview
}

const handlePreviewImgLoad = (e) => {
  const previewImg = e.currentTarget

  if (previewImg.src !== `${window.location.origin}${EMPTY_IMG}`) {
    removeClass(previewImg, SKELETON_CLASS)
    previewImg.style.backgroundColor = 'transparent'
  }

  if (previewImg.src === `${window.location.origin}${notFoundPreview}`
    || previewImg.src === `${window.location.origin}${brokenFilePreview}`) {
    previewImg.style.backgroundColor = PREVIEW_NOT_FOUND_BG_COLOR
  }
}

const Preview = ({
  image, index, refreshGallery, resetInterval,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isDraggableOpened: state.layout.isDraggableOpened,
    draggableIndex: state.layout.draggableIndex,
    pathsColors: state.layout.pathsColors,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateOverlayOpened: actions.layout.updateOverlayOpened,
    updateDraggableOpened: actions.layout.updateDraggableOpened,
    updateDraggableIndex: actions.layout.updateDraggableIndex,
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateDialogModalState: actions.layout.updateDialogModalState,
    updatePathsColors: actions.layout.updatePathsColors,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    links: state.order.links,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    selectedImages: state.order.selectedImages,
    gallery: state.order.gallery,
    selectedImagePaths: state.order.selectedImagePaths,
    paths: state.order.paths,
    selectedLayer: state.order.selectedLayer,
    layerUrls: state.order.layerUrls,
    pathRange: state.order.pathRange,
    selectedGrids: state.order.selectedGrids,
    selectedLayers: state.order.selectedLayers,
    imageDetails: state.order.imageDetails,
    selectedProperties: state.order.selectedProperties,
    selectedUnderlayUrl: state.order.selectedUnderlayUrl,
    selectedUnderlayWidth: state.order.selectedUnderlayWidth,
    selectedUnderlayHeight: state.order.selectedUnderlayHeight,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
    isPreviewsBottomSpace: state.order.isPreviewsBottomSpace,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImagesToBeDeleted: actions.order.setImagesToBeDeleted,
    setOverlayImgIndex: actions.order.setOverlayImgIndex,
    getLinks: actions.order.getLinks,
    imageAction: actions.order.imageAction,
    setSelectedImages: actions.order.setSelectedImages,
    setDownloadUrls: actions.order.setDownloadUrls,
    removeError: actions.order.removeError,
    setImageToBeAllowedOverwrite: actions.order.setImageToBeAllowedOverwrite,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    getPath: actions.order.getPath,
    setSelectedLayers: actions.order.setSelectedLayers,
    getLayer: actions.order.getLayer,
    getImageDetails: actions.order.getImageDetails,
  }))

  const getAvailableWidth = (isFolder = false) => {
    let availableWidth = -10 // extract padding
    if (!isFolder) {
      availableWidth = -30 // extract padding more
      availableWidth -= (image.id.toString().length * 5)
    }
    availableWidth += getPreviewWidth(orderState.imageSize)
    return availableWidth
  }

  const [colorSelectingPath, setColorSelectingPath] = useState(null)
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [layerValues, setLayerValues] = useState({})
  const [filenameAvailableWidth, setFilenameAvailableWidth] = useState(getAvailableWidth())
  const [folderAvailableWidth, setFolderAvailableWidth] = useState(getAvailableWidth(true))
  const [isOriginalImage, setIsOriginalImage] = useState(false)
  const [isBottomMenuOpened, setIsBottomMenuOpened] = useState(false)
  const [isMouseOverDraggable, setIsMouseOverDraggable] = useState(false)
  const [previewSrc, setPreviewSrc] = useState(getPreviewSrc(
    image,
    orderState.links,
    isOriginalImage,
    orderState.imageSize,
    orderState.selectedLayers[image.id],
    orderState.layerUrls,
  ))
  const [isExpanded] = useState(
    localStorage.getItem('isExpanded')
      ? JSON.parse(localStorage.getItem('isExpanded'))
      : DEFAULT_IS_EXPANDED,
  )

  const isOutputImage = !!image.original_image_id
  const commentsLength = image.image_comments?.length
  const inputCommentsLength = image.image_input_comments?.length

  const [previewStyle, imageWrapStyle, previewBgStyle] = getPreviewStyles(
    isOriginalImage ? orderState.imageDetails?.[image.original_image_id]?.width_preview : image.width_preview,
    isOriginalImage ? orderState.imageDetails?.[image.original_image_id]?.height_preview : image.height_preview,
    isOriginalImage ? orderState.imageDetails?.[image.original_image_id]?.image_tag_color : image.image_tag_color,
  )

  const underlayStyle = getUnderlayStyle(
    isOriginalImage ? orderState.imageDetails?.[image.original_image_id]?.width_preview : image.width_preview,
    isOriginalImage ? orderState.imageDetails?.[image.original_image_id]?.height_preview : image.height_preview,
    orderState.selectedUnderlayWidth,
    orderState.selectedUnderlayHeight,
  )

  imageWrapStyle.minWidth = `${getPreviewWidth(orderState.imageSize)}px`

  const [previewClass, previewWrapClass] = getPreviewClass(
    image.id,
    image.is_url_shown,
    image.status,
    commentsLength,
    orderState.selectedImages,
    userState.user.is_admin,
  )

  const draggableImageRef = useRef(null)
  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const filenameRef = useRef(null)
  const folderRef = useRef(null)

  const dotsText = '...'
  const truncateIterationLimit = 1000 // iterations to prevent infinite loop

  useEffect(() => {
    if (!image.name_without_format || !image.format) return

    const row = filenameRef.current
    if (row?.nodeName === 'SPAN') {
      // remove all children from row
      removeAllChildren(row)

      // reset to initial value
      const nameWithoutFormat = document.createTextNode(row.dataset.nameWithoutFormat)
      if (row.dataset.wrongFileName === 'true') {
        const spanNameWithoutFormat = document.createElement('span')
        spanNameWithoutFormat.setAttribute('class', IMAGE_ERROR_CLASS)
        spanNameWithoutFormat.dataset.filename = 'true'
        spanNameWithoutFormat.appendChild(nameWithoutFormat)
        row.appendChild(spanNameWithoutFormat)
      } else {
        row.appendChild(nameWithoutFormat)
      }

      row.appendChild(document.createTextNode('.'))

      const format = document.createTextNode(row.dataset.format)
      if (row.dataset.wrongFormat === 'true') {
        const spanFormat = document.createElement('span')
        spanFormat.setAttribute('class', IMAGE_ERROR_CLASS)
        spanFormat.dataset.format = 'true'
        spanFormat.appendChild(format)
        row.appendChild(spanFormat)
      } else {
        row.appendChild(format)
      }

      if (row.offsetWidth > filenameAvailableWidth && 1) {
        let textNode = row.firstChild

        if (textNode.nodeName !== '#text') {
          textNode = textNode.firstChild
        }
        let value = dotsText + textNode.nodeValue

        let charLimit = 0
        do {
          value = dotsText + value.substring(dotsText.length + 1)
          textNode.nodeValue = value
          charLimit += 1
        } while (charLimit < truncateIterationLimit && row.offsetWidth > filenameAvailableWidth)
      }
    }
  }, [
    filenameAvailableWidth,
    image.name_without_format,
    image.format,
    image.wrong_file_name,
    image.wrong_format,
    orderState.selectedProperties.some((p) => p.name === 'filename'),
  ])

  useEffect(() => {
    if (!image.folder) return

    const row = folderRef.current
    if (row?.nodeName === 'SPAN') {
      row.innerText = row.dataset.folder // reset to initial value

      if (row.offsetWidth > folderAvailableWidth) {
        let value = dotsText + row.dataset.folder

        let charLimit = 0
        do {
          value = dotsText + value.substring(dotsText.length + 1)
          row.innerText = value
          charLimit += 1
        } while (charLimit < truncateIterationLimit && row.offsetWidth > folderAvailableWidth)
      }
    }
  }, [
    folderAvailableWidth,
    image.folder,
    orderState.selectedProperties.some((p) => p.name === 'folder'),
  ])

  useEffect(() => {
    if (layoutState.isDraggableOpened && index === layoutState.draggableIndex) {
      const dragEvent = dragElement(draggableImageRef.current)
      draggableImageRef.current.addEventListener('mousedown', dragEvent)
    }
  }, [layoutState.isDraggableOpened, index, layoutState.draggableIndex, image.id])

  useEffect(() => {
    setIsOriginalImage(false)
    setFilenameAvailableWidth(getAvailableWidth())
    setFolderAvailableWidth(getAvailableWidth(true))
  }, [orderState.imageSize])

  useEffect(() => {
    setPreviewSrc(getPreviewSrc(
      image,
      orderState.links,
      isOriginalImage,
      orderState.imageSize,
      orderState.selectedLayers[image.id],
      orderState.layerUrls,
    ))
  }, [
    image,
    isOriginalImage,
    orderState.imageSize,
    orderState.selectedLayers[image.id],
    orderState.links,
    orderState.layerUrls,
  ])

  useEffect(() => {
    const layers = {}
    image?.layer?.forEach((layer) => {
      layers[layer.id] = (
        <div className="layer-item">
          {parseInt(layer.is_visible, 2) ? <LayerVisibleIconSvg /> : <LayerInvisibleIconSvg />}
          {parseInt(layer.has_layer_mask, 2) ? <LayerMaskIconSvg /> : null}
          <Typography label={layer.name.replace(/&nbsp;/g, ' ')} />
        </div>
      )
    })
    setLayerValues({
      [`${ALL_LAYERS_KEY}-${image.id}`]: (
        <div className="layer-item">
          <Typography label={Translation.all_layers} />
        </div>
      ),
      ...layers,
    })
  }, [image.layer])

  const openOverlay = (e) => {
    if (layoutState.isOverlayOpened) return

    e.stopPropagation()
    if (e.shiftKey) return // images are currently selected with mouse + shift key

    orderActions.setOverlayImgIndex(index)
    layoutActions.updateOverlayOpened(true)
  }

  const toggleOriginalImage = (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    setIsOriginalImage(!isOriginalImage)

    if (!orderState.imageDetails[image.original_image_id]) {
      orderActions.getImageDetails({
        image_ids: [image.original_image_id],
        order_ids: [image.order_id],
        image_type: IMAGE_TYPES.input,
        image_size: orderState.imageSize || IMAGE_SIZES.small,
        is_with_comments: isExpanded.history,
      })
    }

    if (orderState.links[image.original_image_id] && orderState.links[image.original_image_id][IMAGE_SIZES.small]) return

    const imageResource = image.image_resource[image.original_image_id]

    orderActions.getLinks({
      image_ids: [image.original_image_id],
      s3_paths: [imageResource.s3_path],
      original_s3_paths: [imageResource.original_s3_path],
      formats: [imageResource.format],
      thumbnail_types: [imageResource.thumbnail_type],
      image_sizes: [orderState.imageSize || IMAGE_SIZES.small],
    })
  }

  const openDraggable = () => {
    layoutActions.updateDraggableOpened(true)
    layoutActions.updateDraggableIndex(index)

    const imageResource = image.image_resource[image.id]

    orderActions.getLinks({
      image_ids: [image.id],
      s3_paths: [imageResource.s3_path],
      original_s3_paths: [imageResource.original_s3_path],
      formats: [imageResource.format],
      thumbnail_types: [imageResource.thumbnail_type],
      image_sizes: [IMAGE_SIZES.medium],
    })
  }

  const closeDraggable = () => {
    layoutActions.updateDraggableOpened(false)
    layoutActions.updateDraggableIndex(null)
  }

  const toggleDraggable = (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    if (layoutState.isDraggableOpened && index === layoutState.draggableIndex) {
      closeDraggable()
    } else {
      openDraggable()
    }
  }

  useEventListener('keyup', (e) => {
    if (!layoutState.isDraggableOpened) return

    if (e.key === 'Escape') closeDraggable()
  })

  const openBottomMenu = (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    setIsBottomMenuOpened(!isBottomMenuOpened)
    isOpenedRef.current = !isBottomMenuOpened
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        setIsBottomMenuOpened(false)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const isConfirmedApproved = (status, imageTagColor) => !(
    (userState.user.is_admin && parseInt(status, 10) === IMAGE_STATUSES.confirmed)
    || (!userState.user.is_admin && imageTagColor))

  const onHandleImageAction = async (e, action) => {
    e.stopPropagation()
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    const res = await orderActions.imageAction({
      url: action,
      body: {
        image_ids: [image.id],
        ...(action === getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.confirm)
        || action === getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.approve)
          ? { status: isConfirmedApproved(image.status, image.image_tag_color) }
          : {}),
      },
    })

    if (res.success) {
      refreshGallery()
      resetInterval()
    }
  }

  const referenceImageIcon = () => {
    const status = parseInt(image.status.toString(), 10)
    if (status === IMAGE_STATUSES.internalReference
      || status === IMAGE_STATUSES.backgroundCode
      || status === IMAGE_STATUSES.inputSpReference
    ) {
      return <EyeSvg style={{ marginBottom: `${PX_TO_REM['1']}rem` }} />
    }
    if (status === IMAGE_STATUSES.colorationReference || status === IMAGE_STATUSES.colorReference) {
      return <EyeDropperSvg className="width-8 height-8" />
    }
    return null
  }

  const toggleSelect = (e) => {
    e.stopPropagation()
    if (e.shiftKey) return // images are currently selected with mouse + shift key
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    const imageId = parseInt(e.currentTarget.dataset.imageId, 10)
    const selectedImagesCopy = [...orderState.selectedImages]
    const imageIndex = selectedImagesCopy.indexOf(imageId)
    if (imageIndex === -1) {
      selectedImagesCopy.push(imageId)
    } else {
      selectedImagesCopy.splice(imageIndex, 1)
    }
    orderActions.setSelectedImages(selectedImagesCopy)
  }

  const onHandleDelete = (e) => {
    e.stopPropagation()
    setIsBottomMenuOpened(false)
    isOpenedRef.current = false
    orderActions.setImagesToBeDeleted([image.id])
    layoutActions.updateDialogModalState(0)
    layoutActions.updateDialogModalOpened(true)
  }

  const onHandleSend = (e) => {
    onHandleImageAction(e, getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.send)).then(() => {
    })
    setIsBottomMenuOpened(false)
    isOpenedRef.current = false
  }

  const onDownload = async (e) => {
    e.stopPropagation()
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.download),
      body: {
        image_ids: [image.id],
        original_s3_paths: [image.image_resource[image.id].original_s3_path],
      },
    })
    if (res.success) {
      Object.keys(res.urls).forEach(() => {
        orderActions.setDownloadUrls(res.urls)
        setTimeout(() => {
          orderActions.setDownloadUrls({})
        }, INTERVAL_1_SECOND)
      })
    }
  }

  const removeErrorCode = async (e, errorCode) => {
    if (!userState.user.is_admin) return

    if (isAltKey(e)) {
      e.stopPropagation()

      if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
        removeClass(e.currentTarget.firstChild, IMAGE_ERROR_CLASS)
      } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
        removeClass(e.currentTarget.lastChild, IMAGE_ERROR_CLASS)
      } else {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
      }

      const res = await orderActions.removeError({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [image.id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  const handleFileNameClick = async (e) => {
    if (e.target.dataset.filename === 'true') {
      removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName).then(() => {
      })
    } else if (e.target.dataset.format === 'true') {
      removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat).then(() => {
      })
    }
  }

  const handleClearUpload = (e) => {
    e.stopPropagation()
    orderActions.setImageToBeAllowedOverwrite(image.id)
    layoutActions.updateDialogModalState(3)
    layoutActions.updateDialogModalOpened(true)
  }

  const handlePathColorClick = (e, path) => {
    e.stopPropagation()
    setColorSelectingPath(path)
    setOpenColorPicker(true)
  }

  const handlePathColorSelect = (color) => {
    layoutActions.updatePathsColors({
      ...layoutState.pathsColors,
      [colorSelectingPath]: color,
    })
    setColorSelectingPath(null)
    setOpenColorPicker(false)
  }

  const closePicker = () => {
    setColorSelectingPath(null)
    setOpenColorPicker(false)
  }

  const onSelectPath = async (path) => {
    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    if (!selectedImagePathsCopy[image.id]) selectedImagePathsCopy[image.id] = {}
    selectedImagePathsCopy[image.id][path] = !selectedImagePathsCopy[image.id][path]
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    if (!selectedImagePathsCopy?.[image.id]?.[path]) {
      document.getElementById(`${image.id}-${path}-preview`)?.remove()
      return
    }

    if (orderState.paths[image.id]?.[image.path?.[path]?.line.s3_path]) {
      return
    }

    await orderActions.getPath({
      image_ids: [
        image.id,
      ],
      s3_paths: [
        image.path[path]?.line.s3_path,
      ],
    })
  }

  const addPath = (pathName) => {
    const elementString = orderState.paths?.[image.id]?.[image.path?.[pathName]?.line.s3_path]
    if (!elementString || document.getElementById(`${image.id}-${pathName}-preview`)) return

    const svgElement = buildPath(
      `${image.id}-${pathName}-preview`,
      SHOW_PATH_PREVIEW_CLASS,
      elementString,
      image.width_preview,
      image.height_preview,
      orderState.pathRange,
      pathName,
    )

    document.querySelector(`#image-${image.id}`).insertAdjacentElement('afterend', svgElement)
  }

  const onHandleLayerChange = (e) => {
    orderActions.setSelectedLayers({ imageId: image.id, layerId: e.target.value })
  }

  const onHandleMouseEnterDraggable = () => {
    setIsMouseOverDraggable(true)
  }

  const onHandleMouseLeaveDraggable = () => {
    setIsMouseOverDraggable(false)
  }

  useEffect(() => {
    Object.keys(orderState.selectedImagePaths[image.id] || {}).forEach((pathName) => {
      if (orderState.selectedImagePaths[image.id][pathName]) {
        addPath(pathName)
      }
    })
  }, [orderState.selectedImagePaths[image.id], orderState.paths[image.id]])

  useEffect(() => {
    orderState.selectedGrids.forEach((grid) => {
      if (
        document
          .querySelector(
            `.${PREVIEW_GRID_CLASS}.grid-${image.id}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`,
          )
      ) {
        return
      }
      const color = PATH_COLORS[orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)]

      const svgElement = buildGrids(
        grid,
        orderState.gallery?.grids,
        image.width,
        image.height,
        image.width_preview,
        image.height_preview,
        color,
        `${PREVIEW_GRID_CLASS} grid-${image.id}`,
      )

      document.querySelector(`#image-${image.id}`).insertAdjacentElement('afterend', svgElement)
    })
  }, [orderState.selectedGrids])

  useEffect(() => {
    const selectedLayerTemp = orderState.selectedLayers[image.id]
    if (selectedLayerTemp
      && selectedLayerTemp !== `${ALL_LAYERS_KEY}-${image.id}`
      && !orderState.layerUrls?.[image.id]?.[orderState.selectedLayers[image.id]]
    ) {
      const fn = async () => {
        orderActions.getLayer({
          is_admin: userState.user.is_admin,
          body: {
            ids: [parseInt(selectedLayerTemp, 10)],
            image_ids: [image.id],
            image_sizes: [IMAGE_SIZES.extraLarge],
          },
        })
      }

      fn().then(() => {
      })
    }
  }, [orderState.selectedLayers[image.id]])

  useEffect(() => {
    if (image.image_comments.some((c) => c.shapes?.length > 0)) {
      const element = document.querySelector(`.${PREVIEW_COMMENT_DRAWING_CLASS}.preview-drawing-${image.id}`)
      element?.remove()

      const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      svgElement.setAttribute('class', `${PREVIEW_COMMENT_DRAWING_CLASS} preview-drawing-${image.id}`)
      svgElement.setAttribute('id', `preview-drawing-${image.id}`)
      svgElement.setAttribute('style', `width: ${image.width_preview}px; height: ${image.height_preview}px;`)
      svgElement.dataset.sizes = `width: ${image.width_preview}px; height: ${image.height_preview}px;`
      setSvgSizeAndViewBox(svgElement, image.width, image.height)
      document.querySelector(`#image-${image.id}`).insertAdjacentElement('afterend', svgElement)

      const shapes = image?.image_comments?.reduce((acc, curr) => {
        if (curr.shapes && curr.shapes.length) {
          curr.shapes?.forEach((shape) => {
            acc.push(shape)
          })
        }
        return acc
      }, [])

      const strokeWidth = getStrokeWidth(image.width, image.height)
      appendShapes(shapes, strokeWidth, svgElement)
    }
  }, [image.image_comments])

  return (
    <div
      id={`image-${image.id}--wrap`}
      className={`${SELECTABLE_CLASS} ${previewWrapClass}`}
      style={imageWrapStyle}
      data-image-id={image.id}
      onClick={toggleSelect}
    >
      <div
        className={PREVIEW_BG_CLASS}
        style={{
          ...previewBgStyle,
          ...(orderState.previewsBackground === TRANSPARENT_KEY
            ? { backgroundImage: `url(${TRANSPARENT_BG})` }
            : { backgroundColor: orderState.previewsBackground }),
        }}
        onClick={openOverlay}
      >
        <img
          id={`image-${image.id}`}
          src={previewSrc}
          data-id={image.id}
          data-index={index}
          onLoad={handlePreviewImgLoad}
          onError={handlePreviewImgError}
          className={`${PREVIEW_CLASS} ${previewClass}`}
          style={previewStyle}
          alt=""
        />

        {orderState.selectedUnderlayUrl && (
          <img
            src={orderState.selectedUnderlayUrl}
            className="image-preview--underlay"
            style={underlayStyle}
            alt=""
          />
        )}

        {(parseInt(image.status.toString(), 10) === IMAGE_STATUSES.internalReference
            || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.backgroundCode
            || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.inputSpReference
            || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.colorationReference
            || parseInt(image.status.toString(), 10) === IMAGE_STATUSES.colorReference)
          && (
            <div className="reference-badge">
              {referenceImageIcon()}
              {Translation.reference}
            </div>
          )}

        {image.is_test_image === TEST_IMAGE_TYPE && (
          <div className="image-test-badge">
            {Translation.test_image}
          </div>
        )}
      </div>

      {orderState.isPreviewsBottomSpace && (
        <div
          className="image-preview--space"
          style={{ backgroundColor: orderState.galleryBackground }}
        />
      )}

      <div className="image-preview--details">
        <div className="image-preview--details__filename image-preview--details__border">
          <span className="image-preview--details__filename--id">
            <Tooltip
              dataToCopy={isOutputImage ? image.original_image_id.toString() : image.id.toString()}
              text={Translation.click_to_copy}
              copiedText={`${Translation.image_id} ${Translation.copied}`}
            >
              <span>
                {
                  (isOutputImage) ? image.original_image_id : image.id
                }
              </span>
            </Tooltip>
          </span>

          {orderState.selectedProperties.some((p) => p.name === 'filename') && (
            <span className="image-preview--details__filename--filename">
              <Tooltip
                dataToCopy={`${image.name_without_format}.${image.format}`}
                text={`${image.name_without_format}.${image.format}`}
                copiedText={`${Translation.filename} ${Translation.copied}`}
              >
                <span
                  ref={filenameRef}
                  data-wrong-file-name={image.wrong_file_name}
                  data-wrong-format={image.wrong_format}
                  data-name-without-format={image.name_without_format}
                  data-format={image.format}
                  onClick={handleFileNameClick}
                >
                  {' '}
                </span>
              </Tooltip>
            </span>
          )}
        </div>

        {image.folder && orderState.selectedProperties.some((p) => p.name === 'folder') && (
          <div className="image-preview--details--folder image-preview--details__border">
            <Tooltip
              dataToCopy={`/ ${image.folder} / ${image.name_without_format}.${image.format}`}
              text={`/ ${image.folder} /`}
              copiedText={`${Translation.folder_with_filename} ${Translation.copied}`}
            >
              <span
                ref={folderRef}
                data-folder={`/ ${image.folder} /`}
              >
                {' '}
              </span>
            </Tooltip>
          </div>
        )}

        {userState.user.is_admin && image.edited_by && (
          <div
            className="image-preview--details__edited-by image-preview--details__border"
            onClick={handleClearUpload}
          >
            <Tooltip
              text={`${Translation.uploaded_by} 
               ${parseInt(image?.edited_by_user_id, 10) === orderState.gallery?.order?.user_id
                ? Translation.client_label : image.edited_by}.
               ${Translation.click_allow_sp_overwriting}.`}
            >
              <span className={
                parseInt(image?.edited_by_user_id, 10) === SYSTEM_USER_ID ? 'edited-by--crossed-name' : ''
              }
              >
                {parseInt(image?.edited_by_user_id, 10) === orderState.gallery?.order?.user_id
                  ? Translation.client_label : image.edited_by}
              </span>
              {' '}
              <PreviewEditedBySvg />
              {' '}
              <span>
                {localizeDate(image.timestamp_edited)}
              </span>
            </Tooltip>
          </div>
        )}

        {(orderState.selectedProperties.some((p) => p.name === 'resolution')
          || orderState.selectedProperties.some((p) => p.name === 'dpi')
          || orderState.selectedProperties.some((p) => p.name === 'data_sizes')
          || orderState.selectedProperties.some((p) => p.name === 'colour_space')
          || orderState.selectedProperties.some((p) => p.name === 'background_colour')
          || orderState.selectedProperties.some((p) => p.name === 'colour_depth')) && (
          <div className="image-preview--details__sizes--wrap image-preview--details__border">
            <div className="image-preview--details__sizes">
              {orderState.selectedProperties.some((p) => p.name === 'resolution') && (
                <>
                  {image.width_error ? (
                    <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}>
                      {image.width}
                    </span>
                  ) : image.width}
                  {' '}
                  {Translation.times_x_short}
                  {' '}
                  {image.height_error ? (
                    <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}>
                      {image.height}
                    </span>
                  ) : image.height}
                  {' '}
                  {Translation.px}
                  {' '}
                  /
                  {' '}
                </>
              )}

              {orderState.selectedProperties.some((p) => p.name === 'dpi') && (
                <>
                  {image.dpi_error ? (
                    <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}>
                      {image.dpi}
                    </span>
                  ) : image.dpi}
                  {' '}
                  {Translation.dpi}
                  {' '}
                  /
                  {' '}
                </>
              )}

              {orderState.selectedProperties.some((p) => p.name === 'data_sizes') && (
                <>
                  {image.size_mb}
                  {' '}
                  {Translation.mb}
                </>
              )}

              {orderState.selectedProperties.some((p) => p.name === 'resolution')
                && userState.user.is_admin
                && orderState.imageType !== IMAGE_TYPES.input
                && image.template_image_ratio > 0 && (
                <>
                  {' '}
                  /
                  {' '}
                  {image.ratio_error ? (
                    <span className="image-error">
                      {Translation.wrong_ratio}
                      :
                      {' '}
                      {image.image_ratio}
                    </span>
                  ) : (
                    <span>
                      {Translation.ratio}
                      :
                      {' '}
                      {image.image_ratio}
                    </span>
                  )}
                </>
              )}
            </div>

            {orderState.selectedProperties.some((p) => p.name === 'colour_space') && (
              <div className="image-preview--details__colour-space">
                {/* eslint-disable-next-line no-nested-ternary */}
                {image.color_space_error ? (
                  <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}>
                    {image.colour_space ? image.colour_space : Translation.no_colour_space}
                  </span>
                ) : image.colour_space ? image.colour_space : Translation.no_colour_space}
              </div>
            )}

            {orderState.selectedProperties.some((p) => p.name === 'background_colour') && (
              <div className="image-preview--details__bg-colour">
                {/* eslint-disable-next-line no-nested-ternary */}
                {image.no_bg_color ? (
                  <span className="image-error" onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}>
                    {image.background_color
                      ? `${Translation.detected_background}: ${image.background_color}`
                      : Translation.background_colour_not_detected}
                  </span>
                ) : image.background_color
                  ? `${Translation.detected_background}: ${image.background_color}`
                  : Translation.background_colour_not_detected}
              </div>
            )}

            {orderState.selectedProperties.some((p) => p.name === 'colour_depth') && (
              <div className="image-preview--details__colour-depth">
                {Translation.colour_depth}
                :
                {' '}
                {image.colour_depth}
                {' '}
                {Translation.bits}
              </div>
            )}
          </div>
        )}

        {orderState.selectedProperties.some((p) => p.name === 'paths') && Object.keys(image.path).length > 0 && (
          <div className="image-preview--details__path image-preview--details__border
            scrollbar-overflow scrollbar-overflow__small"
          >
            {Object.keys(image.path).map((path, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="path-item" key={i}>
                <div
                  className="path-color"
                  style={{
                    backgroundColor:
                      orderState.selectedImagePaths?.[image.id]?.[path]
                        ? layoutState.pathsColors[path]
                        : null,
                  }}
                  onClick={(e) => handlePathColorClick(e, path)}
                >
                  {openColorPicker && colorSelectingPath === path && (
                    <ColorPicker
                      onColorChange={handlePathColorSelect}
                      closePicker={closePicker}
                      colors={PATH_COLORS}
                    />
                  )}
                </div>
                <Checkbox
                  label={path}
                  checked={orderState.selectedImagePaths?.[image.id]?.[path]}
                  id={`${image.id}-${path}-checkbox`}
                  onChange={() => onSelectPath(path)}
                />
                {
                  image.path[path]?.line.viewbox && <PathOutsideImageIconSvg />
                }
              </div>
            ))}
          </div>
        )}

        {orderState.selectedProperties.some((p) => p.name === 'layers') && (
          image.layer.length > 0 && (
            <div className="image-preview--details__layers">
              <RadioGroup
                container="previewImageLayer"
                values={layerValues}
                onChange={onHandleLayerChange}
                value={orderState.selectedLayers[image.id] || `${ALL_LAYERS_KEY}-${image.id}`}
              />
            </div>
          )
        )}

        {image.prices_extra && (
          <div className="image-preview--details__extra-price image-preview--details__border">
            {image.prices_extra.map((pricesExtra, formatIndex) => (
              <div key={pricesExtra.id}>
                {formatIndex + 1}
                .
                {' '}
                {Translation.format}
                :
                {' '}
                <span>{pricesExtra.price}</span>
                {' '}
                <span>{orderState.gallery?.order.currency}</span>
              </div>
            ))}
          </div>
        )}

        {orderState.selectedProperties.some((p) => p.name === 'exif_data') && (
          image.exif && (
            <div className="image-preview--details__exif image-preview--details__border
            scrollbar-overflow scrollbar-overflow__small"
            >
              {Object.keys(image.exif).map((key) => (
                <div key={key}>
                  {`${key}: ${image.exif[key]}`}
                </div>
              ))}
            </div>
          )
        )}

        {orderState.selectedProperties.some((p) => p.name === 'meta_data')
          && (image.photoshop_instructions || image.photoshop_description || image.photoshop_iptc) && (
            <div className="image-preview--details__photoshop image-preview--details__border
            scrollbar-overflow scrollbar-overflow__small"
            >
              {image.photoshop_instructions && (
                <div>
                  {`Photoshop instructions: ${image.photoshop_instructions}`}
                </div>
              )}
              {image.photoshop_description && (
                <div>
                  {`Photoshop description: ${image.photoshop_description}`}
                </div>
              )}
              {image.photoshop_iptc && (
                <div>
                  {`Photoshop iptc: ${image.photoshop_iptc}`}
                </div>
              )}
            </div>
        )}

        {orderState.selectedProperties.some((p) => p.name === 'ai_labels')
          && image.image_rekognition_label && (
            <div
              className="image-preview--details__label-recognition image-preview--details__border
              scrollbar-overflow scrollbar-overflow__small"
            >
              {Object.keys(image.image_rekognition_label[0]).map((key) => (
                Object.keys(image.image_rekognition_label[0][key]).map((key1) => (
                  <div key={key1}>
                    {`${key1 === 'name' ? image.image_rekognition_label[0][key][key1] : ''}`}
                  </div>
                ))
              ))}
            </div>
        )}
      </div>

      {userState.user.is_admin && isOutputImage && inputCommentsLength > 0 && (
        <>
          <div className="image-preview--comments-header">
            {Translation.input_comments}
          </div>

          <div className="image-preview--details image-preview--comments__wrap">
            {image.image_input_comments.map((imageComment, commentIndex) => (
              <Comment
                key={imageComment.id}
                comment={imageComment}
                isLast={(commentIndex + 1) === inputCommentsLength}
                refreshGallery={refreshGallery}
                resetInterval={resetInterval}
                isInputInOutput
              />
            ))}
          </div>

          {commentsLength > 0 && (
            <div className="image-preview--comments-header" style={{ paddingTop: `${PX_TO_REM['4']}rem` }}>
              {Translation.output_comments}
            </div>
          )}
        </>
      )}

      {commentsLength > 0 && (
        <div className="image-preview--details image-preview--comments__wrap">
          {image.image_comments.map((imageComment, commentIndex) => (
            <Comment
              key={imageComment.id}
              comment={imageComment}
              isLast={(commentIndex + 1) === commentsLength}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
            />
          ))}
        </div>
      )}

      <div
        className="image-preview--details__actions--wrap"
        onMouseLeave={onHandleMouseLeave}
        onMouseEnter={onHandleMouseEnter}
      >
        <div className="image-preview--details__actions">
          {isOutputImage && (
            <Tooltip
              text={isConfirmedApproved(image.status, image.image_tag_color)
                ? Translation.confirm_image : Translation.unconfirmed_image}
            >
              <div
                className={`image-preview--details__actions-action ${NOSELECT_CLASS}`}
                onClick={(e) => onHandleImageAction(
                  e,
                  userState.user.is_admin
                    ? getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.confirm)
                    : getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.approve),
                )}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  isConfirmedApproved(image.status, image.image_tag_color)
                    ? userState.user.is_admin
                      ? <PreviewCheckSvg /> : <ActionLikeSvg className="width-13 height-12 top-1" />
                    : userState.user.is_admin
                      ? <PreviewUndoSvg className="top--2 right-4" />
                      : <ActionDislikeSvg className="width-13 height-12 top-2" />
                }
              </div>
            </Tooltip>
          )}

          {isOutputImage && (
            <Tooltip text={Translation.toggle_original_image}>
              <div
                className={`image-preview--details__actions-action 
                  ${NOSELECT_CLASS} ${isOriginalImage ? ACTIVE_CLASS : ''}`}
                onClick={toggleOriginalImage}
              >
                <PreviewImageSvg className="top-1" />
              </div>
            </Tooltip>
          )}

          <Tooltip text={Translation.download_image}>
            <div
              className={`image-preview--details__actions-action ${NOSELECT_CLASS} download`}
              onClick={onDownload}
            >
              <PreviewDownloadSvg className="top-1" />
            </div>
          </Tooltip>

          <Tooltip text={Translation.open_draggable_image}>
            <div
              className={`image-preview--details__actions-action ${NOSELECT_CLASS}
                ${(layoutState.isDraggableOpened && index === layoutState.draggableIndex) ? ACTIVE_CLASS : ''}`}
              onClick={toggleDraggable}
            >
              <PreviewDuplicateSvg className="top-1" />
            </div>
          </Tooltip>

          <div
            className={`image-preview--details__actions-action ${NOSELECT_CLASS}`}
            onClick={openBottomMenu}
          >
            <PreviewMoreSvg />
          </div>

          {isBottomMenuOpened && (
            <div className="bottom-menu">
              {isOutputImage && (
                <div
                  className="bottom-menu--item"
                  onClick={onHandleSend}
                >
                  <PreviewSendSvg />
                  {Translation.send}
                </div>
              )}

              {userState.user.is_admin && (
                <div
                  className="bottom-menu--item"
                  onClick={onHandleDelete}
                >
                  <PreviewDeleteSvg />
                  {Translation.delete}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {layoutState.isDraggableOpened && index === layoutState.draggableIndex && (
        <div
          className="draggable-wrap"
          ref={draggableImageRef}
          onMouseEnter={onHandleMouseEnterDraggable}
          onMouseLeave={onHandleMouseLeaveDraggable}
          onClick={(e) => e.stopPropagation()}
          style={{ backgroundImage: `url(${TRANSPARENT_BG})` }}
        >
          {isMouseOverDraggable && (
            <OverlayCloseIconSvg className="draggable-close" onClick={closeDraggable} />
          )}
          <img
            id={`image-draggable-${image.id}`}
            src={orderState.links[image.id]?.[IMAGE_SIZES.medium]}
            data-id={image.id}
            data-index={index}
            onLoad={handlePreviewImgLoad}
            onError={handlePreviewImgError}
            className={`image-draggable ${SKELETON_CLASS}`}
            alt=""
          />
        </div>
      )}
    </div>
  )
}

Preview.propTypes = {
  image: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Preview.defaultProps = {
  image: {},
  index: 0,
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Preview
