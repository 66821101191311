import React from 'react'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { redirectToMyDoopic } from '../../helpers/Utils'
import { PX_TO_REM } from '../../helpers/Constants'

import Typography from '../../components/Typography'

import { ReactComponent as HomeSvg } from '../../svg/home.svg'

import './index.scss'

const Home = () => {
  const layoutState = useStoreState((state) => ({
    isMobile: state.layout.isMobile,
  }))

  return (
    <div className="error-page">
      <div className="error--wrap">
        <div className="header-logo--wrap">
          <HomeSvg
            className="header-logo"
            onClick={redirectToMyDoopic}
            title={Translation.back_to_doopic}
          />
        </div>

        <div className="screen-center">
          <div className="width-100vw">
            <div className={`${layoutState.isMobile ? 'm-top-20' : 'm-top-24'} error-title`}>
              <Typography
                variant="h2"
                font="medium"
                label={Translation.no_order_selected}
                fontSize={layoutState.isMobile ? PX_TO_REM['35'] : null}
                lineHeight={layoutState.isMobile ? PX_TO_REM['44'] : PX_TO_REM['72']}
              />
            </div>

            <div className={`${layoutState.isMobile ? 'm-top-18' : 'm-top-40'}`}>
              <button
                type="button"
                className="button-take-home"
                onClick={redirectToMyDoopic}
              >
                {Translation.back_to_doopic}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Home.propTypes = {}

Home.defaultProps = {}

export default Home
