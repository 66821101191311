import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  COMMENT_STATUSES,
  COMMENT_TYPES,
  IMAGE_SIZES,
  INTERVAL_1_SECOND,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import {
  getEndpointUrl,
  localizeDate,
  prepareComment,
  scrollToBottom,
} from '../../../../helpers/Utils'

import Checkbox from '../../../../components/Checkbox'
import Tooltip from '../../../../components/Tooltip'

import { ReactComponent as CommentedDrawingFreehandSvg } from '../../../../svg/commented_drawing_freehand.svg'
import { ReactComponent as CommentedTextSvg } from '../../../../svg/commented_text.svg'
import { ReactComponent as PreviewDownloadSvg } from '../../../../svg/preview_download.svg'

import './index.scss'
import { API_ENDPOINTS_GALLERY } from '../../../../helpers/Urls'

const HistoryOverlay = ({ type, historyWrapRef }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    imageShapes: state.order.imageShapes,
    imageDetails: state.order.imageDetails,
    overlayImgIndex: state.order.overlayImgIndex,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    getImageDetails: actions.order.getImageDetails,
    getVersion: actions.order.getVersion,
    setDownloadUrls: actions.order.setDownloadUrls,
  }))

  const [historyItems, setHistoryItems] = useState(null)
  const [versionChecks, setVersionChecks] = useState([])

  const isHistory = (historyType) => historyType === 'history'

  const fetchImageDetails = async (currentImageId) => {
    await orderActions.getImageDetails({
      image_ids: [currentImageId],
      order_ids: [orderState.orderId],
      image_type: orderState.imageType,
      image_size: IMAGE_SIZES.extraLarge,
      is_with_comments: isHistory(type),
    })
  }

  const fetchImageVersion = async (id) => {
    const currentImageId = orderState.images[orderState.overlayImgIndex]?.id
    if (!currentImageId) return
    if (!orderState.imageDetails[currentImageId]?.image_versions) return

    const imageVersions = orderState.imageDetails[currentImageId]?.image_versions.find((item) => item.id === id)
    const image = orderState.images[orderState.overlayImgIndex]
    const imageResource = image.image_resource[image.id]

    await orderActions.getVersion({
      image_ids: [image.id],
      s3_paths: [imageResource.s3_path],
      original_s3_paths: [imageResource.original_s3_path],
      formats: [imageResource.format],
      image_sizes: IMAGE_SIZES.extraLarge,
      thumbnail_types: [imageResource.thumbnail_type],
      versions: [imageVersions.version],
    })
  }

  useEffect(() => {
    if (!orderState.imageDetails) return
    const currentImageId = orderState.images[orderState.overlayImgIndex]?.id
    if (!currentImageId) return

    if (isHistory(type)) {
      const imageHistory = orderState.imageDetails[currentImageId]?.image_history
      if (!imageHistory) {
        fetchImageDetails(currentImageId).then(() => { })
      } else {
        setHistoryItems(imageHistory)
        setVersionChecks(imageHistory)
        scrollToBottom(historyWrapRef.current)
      }
    } else {
      const imageVersions = orderState.imageDetails[currentImageId]?.image_versions
      setHistoryItems(imageVersions)
      setVersionChecks(imageVersions)
      scrollToBottom(historyWrapRef.current)
    }
  }, [
    type,
    orderState.overlayImgIndex,
    orderState.imageDetails,
  ])

  const onChangeImageVersion = (id, checked) => {
    setVersionChecks((prevChecks) => prevChecks.map((version) => ({
      ...version,
      checked: version.id === id ? checked : false,
    })))

    fetchImageVersion(id).then(() => { })
  }

  const onClickImageVersionDownload = async (version) => {
    const image = orderState.images[orderState.overlayImgIndex]
    const imageResource = image.image_resource[image.id]

    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.download),
      body: {
        image_ids: [image.id],
        original_s3_paths: [imageResource.original_s3_path],
        thumbnail_types: [imageResource.thumbnail_type],
        versions: [version],
      },
    })
    if (res.success) {
      Object.keys(res.urls).forEach(() => {
        orderActions.setDownloadUrls(res.urls)
        setTimeout(() => {
          orderActions.setDownloadUrls({})
        }, INTERVAL_1_SECOND)
      })
    }
  }

  const onMouseEnter = () => {
    // TODO: show hovered shape in fullscreen view
  }

  const onMouseLeave = () => {
    // TODO: reset shapes in fullscreen view
  }

  return (
    historyItems && historyItems.map(
      (historyItem, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`overlay--comment__wrap ${historyItem.status === COMMENT_STATUSES.deleted
            ? 'overlay--comment__wrap--deleted' : ''}`}
          onMouseEnter={historyItem.comment ? onMouseEnter : null}
          onMouseLeave={historyItem.comment ? onMouseLeave : null}
        >
          {historyItem.comment ? (
            <>
              <div className="overlay--comment__icon overlay--comment__type">
                {historyItem.shapes
                  ? <CommentedDrawingFreehandSvg stroke={historyItem.colour} />
                  : <CommentedTextSvg className="right-2" />}
              </div>

              {historyItem.type === COMMENT_TYPES.qaClient && (
                <div className={`overlay--comment__icon overlay--comment__icon--client 
                  ${parseInt(historyItem.status.toString(), 10) === COMMENT_STATUSES.active
                  ? 'overlay--comment__icon--client__approved' : ''}`}
                >
                  {Translation.client_short}
                </div>
              )}

              <div className="overlay--comment">
                {prepareComment(historyItem.comment)}
                {' '}
                <span className="overlay--comment__note">
                  {historyItem.nickname && (
                    <>
                      <Tooltip text={`${historyItem.firstname} ${historyItem.lastname}`}>
                        {historyItem.nickname}
                      </Tooltip>
                      {' '}
                      /
                      {' '}
                    </>
                  )}
                  <Tooltip text={localizeDate(historyItem.timestamp)}>
                    {localizeDate(historyItem.timestamp)}
                  </Tooltip>
                </span>
              </div>
            </>
          ) : (
            <>
              <Checkbox
                id={`version-${historyItem.id}`}
                label={historyItem.name}
                checked={versionChecks.some((version) => version.id === historyItem.id && version.checked)}
                onChange={(e) => onChangeImageVersion(historyItem.id, e.target.checked)}
              />
              <div className="overlay--comment">
                <span className="overlay--comment__note">
                  <Tooltip text={localizeDate(historyItem.timestamp)}>
                    {localizeDate(historyItem.timestamp)}
                  </Tooltip>
                </span>
              </div>
              <PreviewDownloadSvg
                className="overlay--comment__download"
                onClick={() => onClickImageVersionDownload(historyItem.version_download)}
              />
            </>
          )}
        </div>
      ),
    )
  )
}

HistoryOverlay.propTypes = {
  type: PropTypes.string,
  historyWrapRef: PropTypes.instanceOf(Object),
  overlayImgDrawingRef: PropTypes.instanceOf(Object),
}

HistoryOverlay.defaultProps = {
  type: '',
  historyWrapRef: {},
  overlayImgDrawingRef: {},
}

export default HistoryOverlay
