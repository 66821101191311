import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  DEFAULT_TEXT_COLOR,
  IMAGE_PREVIEWS_WIDTHS,
  IMAGE_TYPES,
  IMAGES_PER_PAGE,
  SKELETON_CLASS,
} from '../../../helpers/Constants'
import { MY_DOOPIC_URLS } from '../../../helpers/Urls'
import { getContrastColor, getPreviewWidth } from '../../../helpers/Utils'

import Preview from './Preview'

import { ReactComponent as ScissorSvg } from '../../../svg/scissor.svg'
import { ReactComponent as MagnifierSvg } from '../../../svg/magnifier.svg'
import { ReactComponent as LoaderSvg } from '../../../svg/loader.svg'

import './index.scss'

const Previews = ({ refreshGallery, resetInterval }) => {
  const orderState = useStoreState((state) => ({
    filteredImages: state.order.filteredImages,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    gallery: state.order.gallery,
    isImagesLoading: state.order.isImagesLoading,
    galleryBackground: state.order.galleryBackground,
  }))

  const [previewsCount, setPreviewsCount] = useState(0)
  const [textColor, setTextColor] = useState(DEFAULT_TEXT_COLOR)
  const [previewWidth, setPreviewWidth] = useState(IMAGE_PREVIEWS_WIDTHS.small)

  useEffect(() => {
    if (orderState.imageType === IMAGE_TYPES.input && orderState.gallery?.input_count) {
      setPreviewsCount(orderState.gallery?.input_count < IMAGES_PER_PAGE
        ? orderState.gallery?.input_count : IMAGES_PER_PAGE)
    } else if (orderState.imageType === IMAGE_TYPES.output && orderState.gallery?.output_count) {
      setPreviewsCount(orderState.gallery?.output_count < IMAGES_PER_PAGE
        ? orderState.gallery?.output_count : IMAGES_PER_PAGE)
    } else if (orderState.imageType === IMAGE_TYPES.compare && orderState.gallery?.compare_count) {
      setPreviewsCount(orderState.gallery?.compare_count < IMAGES_PER_PAGE
        ? orderState.gallery?.compare_count : IMAGES_PER_PAGE)
    }
  }, [
    orderState.gallery?.input_count,
    orderState.gallery?.output_count,
    orderState.gallery?.compare_count,
    orderState.imageType,
  ])

  useEffect(() => {
    setTextColor(getContrastColor(orderState.galleryBackground))
  }, [orderState.galleryBackground])

  useEffect(() => {
    setPreviewWidth(getPreviewWidth(orderState.imageSize))
  }, [orderState.imageSize])

  return (
    // eslint-disable-next-line no-nested-ternary
    orderState.filteredImages ? (
      orderState.filteredImages.length ? (
        <>
          {orderState.filteredImages.map((image, index) => (
            <Preview
              key={image.id}
              image={image}
              index={index}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
            />
          ))}
        </>
      ) : (
        <div className="empty-result">
          <ScissorSvg
            style={{ color: textColor }}
          />
          {orderState.imageType === IMAGE_TYPES.input
            ? (
              <h6
                className="h6"
                style={{ color: textColor }}
              >
                {Translation.sorry_no_images_found}
              </h6>
            )
            : (
              <h6
                className="h6"
                style={{ color: textColor }}
              >
                {Translation.no_output_images_found_1}
                <br />
                {Translation.no_output_images_found_2}
              </h6>
            )}
        </div>
      )
    ) : (
      // eslint-disable-next-line no-nested-ternary
      orderState.isImagesLoading ? (
        previewsCount > 0 ? (
          <>
            {[...Array(previewsCount)].map((x, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="gallery-image--wrap"
                style={{ width: `${previewWidth}px`, height: `${previewWidth * 1.5 + 100}px` }}
              >
                <div
                  className={`image-preview--bg ${SKELETON_CLASS}`}
                  style={{ width: `${previewWidth}px`, height: `${previewWidth * 1.5 + 100}px` }}
                />
                <div className="image-preview--details">
                  <div className="image-preview--details__filename">
                    <span className="image-preview--details__filename--filename">
                      <div
                        className={SKELETON_CLASS}
                        style={{
                          width: '100px',
                          height: '10px',
                          marginBottom: '10px',
                          marginTop: '5px',
                        }}
                      />
                    </span>
                  </div>
                  <div className="image-preview--details__sizes--wrap">
                    <div className="image-preview--details__sizes">
                      <div
                        className={SKELETON_CLASS}
                        style={{
                          width: '150px',
                          height: '10px',
                          marginBottom: '10px',
                        }}
                      />
                    </div>
                    <div className="image-preview--details__colour-space">
                      <div
                        className={SKELETON_CLASS}
                        style={{
                          width: '140px',
                          height: '10px',
                          marginBottom: '10px',
                        }}
                      />
                    </div>
                    <div className="image-preview--details__bg-colour">
                      <div
                        className={SKELETON_CLASS}
                        style={{
                          width: '120px',
                          height: '10px',
                          marginBottom: '10px',
                        }}
                      />
                    </div>
                    <div className="image-preview--details__colour-depth">
                      <div
                        className={SKELETON_CLASS}
                        style={{
                          width: '110px',
                          height: '10px',
                          marginBottom: '10px',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="loader--wrap">
            <LoaderSvg color={getContrastColor(orderState.galleryBackground)} />
          </div>
        )
      ) : (
        <div className="empty-result">
          <MagnifierSvg
            style={{ color: textColor }}
          />
          <h6 className="h6">
            {Translation.we_could_not_find_order}
            {' '}
            <a href={MY_DOOPIC_URLS.support} target="_blank" rel="noreferrer">{Translation.support}</a>
            {' '}
            {Translation.for_assistance}
            .
          </h6>
        </div>
      )
    )
  )
}

Previews.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Previews.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Previews
