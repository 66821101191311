import { useEffect, useRef } from 'react'
import spacetime from 'spacetime'

import { lang } from './Translation'
import { Cookie, isLocal } from './Cookie'
import {
  ADMIN_GALLERY_PREFIX,
  CLIENT_GALLERY_PREFIX,
  DEFAULT_DRAWING_TYPE,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TIMEZONE,
  GALLERY_BG_DEFAULT,
  GRID_COLORS,
  HORIZONTAL_GRID_CLASS,
  IMAGE_PREVIEWS_WIDTHS,
  IMAGE_SIZES,
  IMAGE_SORTINGS,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  OVERLAY_GRID_CLASS,
  PATH_COLORS,
  PATH_DISPLAY_RATIO,
  STROKE_WIDTH_RATIO,
  VERTICAL_GRID_CLASS,
} from './Constants'
import {
  GALLERY_PREFIX_RE,
  MY_DOOPIC_URLS,
} from './Urls'

export const isAuthenticated = () => !!Cookie.getCookie('PHPSESSID')

export const redirectToMyDoopic = () => {
  if (isAuthenticated()) {
    window.location.href = MY_DOOPIC_URLS.dashboard
  } else {
    window.location.href = MY_DOOPIC_URLS.login
  }
}

export const log = (...arg) => {
  // eslint-disable-next-line no-console
  if (isLocal) console.log(...arg)
}

export const isAltKey = (e) => e.altKey || e.metaKey

export const debounce = (func, timeout = 100) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const windowWidth = () => document.documentElement.clientWidth || document.body.clientWidth

log('lang', `"${lang}"`, 'windowWidth', windowWidth())

// not used yet
export const localizeDate = (date, withHour = true) => {
  if (date === null || date === '') return '-'
  let targetTimeInUserTimezone
  try {
    const targetDateTime = spacetime(date, DEFAULT_TIMEZONE)

    const now = spacetime.now()

    let currentTimezone = now.timezone().name
    if (currentTimezone === 'cet') currentTimezone = DEFAULT_TIMEZONE
    targetTimeInUserTimezone = targetDateTime.goto(currentTimezone)
  } catch (e) {
    return date
  }

  if (withHour) {
    return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')} 
  ${new Date(targetTimeInUserTimezone.epoch).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })}`
  }

  return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')}`
}

export const getGalleryUrl = (isAdmin, imageType, orderId, imageSize, imageSort, isCombineExplode) => {
  let galleryUrl

  if (isAdmin) galleryUrl = ADMIN_GALLERY_PREFIX
  else galleryUrl = CLIENT_GALLERY_PREFIX

  galleryUrl += `/${imageType}/${orderId}`

  if (imageSize !== IMAGE_SIZES.small
    || imageSort !== IMAGE_SORTINGS.id
    || isCombineExplode
  ) galleryUrl += `/${imageSize}`

  if (imageSort !== IMAGE_SORTINGS.id
    || isCombineExplode
  ) galleryUrl += `/${imageSort}`

  if (isCombineExplode) galleryUrl += '/1'

  return galleryUrl
}

export const getPreviewWidth = (imageSize) => {
  if (imageSize === IMAGE_SIZES.medium) return IMAGE_PREVIEWS_WIDTHS.medium
  if (imageSize === IMAGE_SIZES.large) return IMAGE_PREVIEWS_WIDTHS.large
  return IMAGE_PREVIEWS_WIDTHS.small
}

export const getEndpointUrl = (isAdmin, url) => url.replace(GALLERY_PREFIX_RE, isAdmin
  ? ADMIN_GALLERY_PREFIX : CLIENT_GALLERY_PREFIX)

const notFoundGalleryEn = '/svg/not_found_gallery_en.svg'
const notFoundGalleryDe = '/svg/not_found_gallery_de.svg'
const notFoundGalleryIt = '/svg/not_found_gallery_it.svg'

const brokenFileGalleryEn = '/svg/broken_file_gallery_en.svg'
const brokenFileGalleryDe = '/svg/broken_file_gallery_de.svg'
const brokenFileGalleryIt = '/svg/broken_file_gallery_it.svg'

const previewGeneratedGalleryEn = '/svg/preview_generated_gallery_en.svg'
const previewGeneratedGalleryDe = '/svg/preview_generated_gallery_de.svg'
const previewGeneratedGalleryIt = '/svg/preview_generated_gallery_it.svg'

const getNotFoundPreview = (type = '') => {
  let preview

  if (typeof lang !== 'undefined' && lang === 'de') {
    if (type === 'broken') preview = brokenFileGalleryDe
    else if (type === 'generated') preview = previewGeneratedGalleryDe
    else preview = notFoundGalleryDe
  } else if (typeof lang !== 'undefined' && lang === 'it') {
    if (type === 'broken') preview = brokenFileGalleryIt
    else if (type === 'generated') preview = previewGeneratedGalleryIt
    else preview = notFoundGalleryIt
  } else if (type === 'broken') preview = brokenFileGalleryEn
  else if (type === 'generated') preview = previewGeneratedGalleryEn
  else preview = notFoundGalleryEn

  return preview
}

export const isBrokenImage = (status) => parseInt(status, 10) === IMAGE_STATUSES.broken

export const notFoundPreview = getNotFoundPreview()

export const brokenFilePreview = getNotFoundPreview('broken')

export const addClass = (el, className) => {
  if (!el || !className) return

  if (className.trim()) {
    if (el.classList) el.classList.add(className)
    // eslint-disable-next-line no-param-reassign
    else el.className += ` ${className}`
  }
}

export const hasClass = (el, className) => {
  if (!el || !className) return false

  return el.classList.contains(className)
}

export const removeClass = (el, className) => {
  if (!el || !className) return
  if (el.classList.contains(className)) el.classList.remove(className)
}

export const prepareComment = (comment) => comment.replace(/&#39;/g, '\'')

// this rounding is used, to place image/layers in fullscreen view "as precise as possible"
export const parseFloatRound = (value, decimals = 2) => {
  const num = parseFloat(value)
  if (Number.isNaN(num)) return 0
  return parseFloat(num.toFixed(decimals))
}

export const parseFloatWithComma = (value) => parseFloat(value.replace(/,/g, '.').replace(/\.(?=.*\.)/g, ''))

export const dragElement = (element, isSpaceRequired = false) => {
  // ------------------------------------------------------------
  let isSpaceKeyDown = false
  // eslint-disable-next-line no-unused-vars
  let isCommandKeyDown = false

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      isSpaceKeyDown = true
      // eslint-disable-next-line no-param-reassign
      element.style.cursor = 'grab'
    } else if (e.keyCode === 91) {
      isCommandKeyDown = true
    }
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 32) {
      isSpaceKeyDown = false
      // eslint-disable-next-line no-param-reassign
      element.style.cursor = ''
    } else if (e.keyCode === 91) {
      isCommandKeyDown = false
    }
  }

  document.onkeydown = handleKeyDown
  document.onkeyup = handleKeyUp

  // eslint-disable-next-line no-param-reassign
  element.onmouseenter = () => {
    if (!isSpaceRequired) {
      // eslint-disable-next-line no-param-reassign
      element.style.cursor = 'grab'
    }
  }

  // eslint-disable-next-line no-param-reassign
  element.onmouseleave = () => {
    if (!isSpaceRequired) {
      // eslint-disable-next-line no-param-reassign
      element.style.cursor = ''
    }
  }

  // ------------------------------------------------------------
  let pos1 = 0
  let pos2 = 0
  let pos3 = 0
  let pos4 = 0

  const elementDrag = (e) => {
    if (isSpaceRequired && !isSpaceKeyDown) return

    e.preventDefault()

    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY

    // set the element's new position:
    // eslint-disable-next-line no-param-reassign
    element.style.top = `${(element.offsetTop - pos2)}px`
    // eslint-disable-next-line no-param-reassign
    element.style.left = `${(element.offsetLeft - pos1)}px`
  }

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }

  return (e) => {
    if (isSpaceRequired && !isSpaceKeyDown) return

    e.preventDefault()

    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement

    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }
}

export const useEventListener = (eventName, handler, element = document) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return () => {
      }
    }

    const eventListener = (event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => element.removeEventListener(eventName, eventListener)
  }, [eventName, element])

  return savedHandler
}

export const removeAllChildren = (el) => {
  while (el.firstChild) {
    el.removeChild(el.lastChild)
  }
}

export const getPathColor = (index) => {
  if (index < PATH_COLORS.length) return PATH_COLORS[index]
  return PATH_COLORS[index % PATH_COLORS.length]
}

export const getGridColor = (index) => {
  if (index < GRID_COLORS.length) return GRID_COLORS[index]
  return GRID_COLORS[index % GRID_COLORS.length]
}

export const getStrokeWidth = (width, height) => parseFloatRound(Math.max(width, height) / STROKE_WIDTH_RATIO)

export const updateVerticalGridLine = (lineElement, value, width, height) => {
  let val
  if (Math.sign(value) !== -1) val = (value < 1 ? value * width : value)
  else val = width + value

  lineElement.setAttribute('x1', val)
  lineElement.setAttribute('y1', '0')
  lineElement.setAttribute('x2', val)
  lineElement.setAttribute('y2', height)
}

export const updateHorizontalGridLine = (lineElement, value, width, height) => {
  let val
  if (Math.sign(value) !== -1) val = (value < 1 ? value * height : value)
  else val = height + value

  lineElement.setAttribute('x1', '0')
  lineElement.setAttribute('y1', val)
  lineElement.setAttribute('x2', width)
  lineElement.setAttribute('y2', val)
}

export const buildGridLine = (value, width, height, realWidth, realHeight, color, strokeWidth, isVertical = false) => {
  const lineElement = document.createElementNS('http://www.w3.org/2000/svg', 'line')

  if (isVertical) {
    lineElement.setAttribute('class', VERTICAL_GRID_CLASS)
    updateVerticalGridLine(lineElement, value, width, height)
  } else {
    lineElement.setAttribute('class', HORIZONTAL_GRID_CLASS)
    updateHorizontalGridLine(lineElement, value, width, height)
  }

  lineElement.setAttribute('stroke', color)
  if (strokeWidth) lineElement.setAttribute('stroke-width', strokeWidth)
  else lineElement.removeAttribute('stroke-width')

  return lineElement
}

export const setSvgSizeAndViewBox = (svgElement, width, height) => {
  svgElement.setAttribute('width', width)
  svgElement.setAttribute('height', height)
  svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`)
}

export const buildGrids = (grid, grids, width, height, realWidth, realHeight, color, className) => {
  const gridElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  gridElement.setAttribute('class', `${className} grid-${grids?.findIndex((g) => g.name === grid.name)}`)
  gridElement.setAttribute('style', `width: ${realWidth}px; height: ${realHeight}px;`)
  gridElement.dataset.sizes = `width: ${realWidth}px; height: ${realHeight}px;`
  setSvgSizeAndViewBox(gridElement, width, height)

  let strokeWidth = 1
  if (className !== OVERLAY_GRID_CLASS) strokeWidth = getStrokeWidth(width, height)
  // else strokeWidth = getStrokeWidth(width, height)

  grid?.left?.forEach((left) => {
    const lineElement = buildGridLine(
      left,
      width,
      height,
      realWidth,
      realHeight,
      color,
      strokeWidth,
      true,
    )
    gridElement.appendChild(lineElement)
  })

  grid?.top?.forEach((top) => {
    const lineElement = buildGridLine(
      top,
      width,
      height,
      realWidth,
      realHeight,
      color,
      strokeWidth,
      false,
    )
    gridElement.appendChild(lineElement)
  })

  return gridElement
}

export const buildPath = (id, className, elementString, width, height, strokeWidth, pathName) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(elementString, 'text/html')

  const svgElement = doc.body.firstChild
  svgElement.setAttribute('id', id)
  svgElement.setAttribute('class', className)
  svgElement.setAttribute('style', `width: ${width}px; height: ${height}px;`)
  svgElement.dataset.pathName = pathName

  const pathElement = svgElement.getRootNode()?.querySelector('path')
  pathElement?.setAttribute('stroke', JSON.parse(localStorage.getItem('pathsColors'))[pathName])
  pathElement?.setAttribute('stroke-width', strokeWidth / PATH_DISPLAY_RATIO)

  return svgElement
}

export const isAllImagesLoaded = (
  imageType,
  imagesCount,
  inputCount = 0,
  outputCount = 0,
  compareCount = 0,
) => {
  let isAll = false
  if (imageType === IMAGE_TYPES.input && imagesCount === inputCount) isAll = true
  else if (imageType === IMAGE_TYPES.output && imagesCount === outputCount) isAll = true
  else if (imageType === IMAGE_TYPES.compare && imagesCount === compareCount) isAll = true

  /* log(
    'isAllImagesLoaded',
    isAll,
    'imageType',
    imageType,
    'imagesCount',
    imagesCount,
    'inputCount',
    inputCount,
    'outputCount',
    outputCount,
    'compareCount',
    compareCount,
  ) */

  return isAll
}

export const createArrowLine = (x1, y1, x2, y2, stroke, strokeWidth) => {
  const line = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'line',
  )
  line.setAttribute('x1', x1)
  line.setAttribute('y1', y1)
  line.setAttribute('x2', x2)
  line.setAttribute('y2', y2)
  line.setAttribute('stroke', stroke)
  line.setAttribute('stroke-width', strokeWidth)
  return line
}

export const drawArrowhead = (x1, y1, x2, y2, stroke, strokeWidth) => {
  const arrowLength = 8 * strokeWidth
  const arrowAngle = Math.atan2(y2 - y1, x2 - x1)
  const arrowPoint1 = {
    x: x2 - arrowLength * Math.cos(arrowAngle - Math.PI / 6),
    y: y2 - arrowLength * Math.sin(arrowAngle - Math.PI / 6),
  }
  const arrowPoint2 = {
    x: x2 - arrowLength * Math.cos(arrowAngle + Math.PI / 6),
    y: y2 - arrowLength * Math.sin(arrowAngle + Math.PI / 6),
  }

  return [
    createArrowLine(x2, y2, arrowPoint1.x, arrowPoint1.y, stroke, strokeWidth),
    createArrowLine(x2, y2, arrowPoint2.x, arrowPoint2.y, stroke, strokeWidth),
  ]
}

export const appendShapes = (shapes, strokeWidth, container) => {
  shapes.forEach((shapeData) => {
    let shapeElement

    switch (shapeData.type) {
      case 'line':
      case 'arrow':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line',
        )
        shapeElement.setAttribute('x1', shapeData.x1)
        shapeElement.setAttribute('y1', shapeData.y1)
        shapeElement.setAttribute('x2', shapeData.x2)
        shapeElement.setAttribute('y2', shapeData.y2)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        if (shapeData.type === 'arrow') {
          const [arrowHeadLine1, arrowHeadLine2] = drawArrowhead(
            shapeData.x1,
            shapeData.y1,
            shapeData.x2,
            shapeData.y2,
            shapeData.stroke,
            strokeWidth,
          )
          container.appendChild(arrowHeadLine1)
          container.appendChild(arrowHeadLine2)
        }
        break
      case DEFAULT_DRAWING_TYPE:
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'ellipse',
        )
        shapeElement.setAttribute('cx', shapeData.cx)
        shapeElement.setAttribute('cy', shapeData.cy)
        shapeElement.setAttribute('rx', shapeData.rx)
        shapeElement.setAttribute('ry', shapeData.ry)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      case 'rectangular':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'rect',
        )
        shapeElement.setAttribute('x', shapeData.x)
        shapeElement.setAttribute('y', shapeData.y)
        shapeElement.setAttribute('width', shapeData.width)
        shapeElement.setAttribute('height', shapeData.height)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      case 'freehand':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        )
        shapeElement.setAttribute('d', shapeData.d)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      default:
        break
    }

    if (shapeElement) container.appendChild(shapeElement)
  })
}

export const getContrastColor = (hexColor = GALLERY_BG_DEFAULT) => {
  // if (['#ffffff', '#fff', '#000000', '#000'].includes(hexColor.toLowerCase())) return DEFAULT_TEXT_COLOR
  if (['#ffffff', '#fff'].includes(hexColor.toLowerCase())) return DEFAULT_TEXT_COLOR

  // Remove the hash at the start if it's there
  const hex = hexColor.replace(/^#/, '')

  // Parse the hex color into its R, G, and B components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}

export const scrollToBottom = (el, timeout = 10) => {
  if (!el) return
  setTimeout(() => {
    // eslint-disable-next-line no-param-reassign
    el.scrollTop = el.scrollHeight
  }, timeout)
}
