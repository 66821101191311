import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { redirectToMyDoopic } from '../../helpers/Utils'
import { PX_TO_REM } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'

import Typography from '../../components/Typography'

import { ReactComponent as HomeSvg } from '../../svg/home.svg'

import './index.scss'

const Error = ({
  errorType, errorTitle, errorDescr, btnLabel,
}) => {
  const layoutState = useStoreState((state) => ({
    isMobile: state.layout.isMobile,
  }))

  return (
    <div className="error-page">
      <div className="header-logo--wrap">
        <HomeSvg
          className="header-logo"
          onClick={redirectToMyDoopic}
          title={Translation.back_to_doopic}
        />
      </div>

      <div className="error--wrap">
        <div className="screen-center">
          <div className="width-100vw">
            {errorType && (
              <div className="error-type">
                <Typography
                  font="semibold"
                  label={errorType}
                  fontSize={PX_TO_REM['166']}
                  lineHeight={PX_TO_REM['34']}
                />
              </div>
            )}

            <div className={`${layoutState.isMobile ? 'm-top-20' : 'm-top-24'} error-title`}>
              <Typography
                variant="h2"
                font="medium"
                label={errorTitle}
                lineHeight={PX_TO_REM['90']}
                fontSize={PX_TO_REM['60']}
              />
            </div>

            <div className={`${layoutState.isMobile ? 'm-top-10' : 'm-top-24'} error-descr`}>
              <Typography
                variant="h6"
                label={errorDescr}
                lineHeight={PX_TO_REM['25']}
                fontSize={PX_TO_REM['20']}
              />
            </div>

            <div className={`${layoutState.isMobile ? 'm-top-18' : 'm-top-40'}`}>
              <button
                type="button"
                className="button-take-home"
                onClick={redirectToMyDoopic}
              >
                {btnLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Error.propTypes = {
  errorType: PropTypes.string,
  errorTitle: PropTypes.string,
  errorDescr: PropTypes.string,
  btnLabel: PropTypes.string,
}

Error.defaultProps = {
  errorType: '',
  errorTitle: '',
  errorDescr: '',
  btnLabel: '',
}

export default Error
