import React from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import { prepareComment } from '../../../../helpers/Utils'

import './index.scss'

const CommentSuggestionOverlay = ({ comment }) => {
  const orderActions = useStoreActions((actions) => ({
    setImageCommentText: actions.order.setImageCommentText,
  }))

  const onCommentClick = () => {
    orderActions.setImageCommentText(comment.comment)
    document.getElementsByClassName('comment-textarea')[0].focus()
  }

  return (
    <div className="overlay--comment-suggestion" onClick={onCommentClick}>
      {prepareComment(comment.comment)}
    </div>
  )
}

CommentSuggestionOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
}

CommentSuggestionOverlay.defaultProps = {
  comment: {},
}

export default CommentSuggestionOverlay
