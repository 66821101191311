export const MOBILE_BREAKPOINT = 430 // must correspond with CSS breakpoint
export const TABLET_BREAKPOINT = 880 // must correspond with CSS breakpoint
// export const DESKTOP_PX_TO_REM = 13 // must correspond with CSS // not used yet

export const CENTER_TOP = '44%'

export const PX_TO_REM = { // all values are exact copy of values in "variables.scss"
  1: 0.0769230,
  2: 0.1538461,
  3: 0.2307692,
  4: 0.3076923,
  5: 0.3846153,
  6: 0.4615384,
  7: 0.5384615,
  8: 0.6153846,
  9: 0.6923076,
  10: 0.7692307,
  11: 0.8461538,
  12: 0.9230769,
  13: 1.0000000,
  14: 1.0769230,
  15: 1.1538461,
  16: 1.2307692,
  17: 1.3076923,
  18: 1.3846153,
  20: 1.5384615,
  21: 1.6153846,
  24: 1.8461538,
  25: 1.9230769,
  26: 2.0000000,
  34: 2.6153846,
  35: 2.6923076,
  36: 2.7692307,
  42: 3.2307692,
  44: 3.3846153,
  60: 4.6153846,
  72: 5.5384615,
  90: 6.9230769,
  100: 7.6923076,
  140: 10.769230,
  166: 12.769230,
  190: 14.615384,
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin'

export const EMPTY_IMG = '/image/about_blank.gif'
export const TRANSPARENT_BG = '/image/transparent_bg.png'
// export const PATTERN_BG = '/image/pattern_bg.png' // not used yet

export const TRANSPARENT_KEY = 'transparent'

export const CLIENT_GALLERY_PREFIX = '/gallery'
// export const PUBLIC_GALLERY_PREFIX = '/public/gallery' // not used yet
export const ADMIN_GALLERY_PREFIX = '/admin/gallery'

export const HIDE_DROPDOWN_TIMEOUT = 750

export const SYSTEM_USER_ID = 1

// export const DEFAULT_THUMBNAIL_TYPE = 2 // not used yet

// must correspond with CSS class
export const IMAGE_ERROR_CLASS = 'image-error'
export const INFO_ERROR_CLASS = 'info-error'
export const SKELETON_CLASS = 'skeleton'
export const NOSELECT_CLASS = 'noselect'
export const SELECTABLE_CLASS = 'selectable'
export const ACTIVE_CLASS = 'active'
export const DISABLED_CLASS = 'disabled'
export const EXPANDED_CLASS = 'expanded'
export const LOADED_CLASS = 'loaded'
export const SELECTED_CLASS = 'selected'
export const SELECTED_IMAGES_CLASS = 'gallery-image--wrap__selected'
export const DRAG_SELECT_CLASS = 'drag-select'
export const OVERLAY_WRAP_CLASS = 'image-overlay--image__wrap'
export const OVERLAY_IMG_CLASS = 'image-overlay--img'
export const OVERLAY_GRID_CLASS = 'image-overlay--grid'
export const OVERLAY_GUIDE_CLASS = 'image-overlay--guide'
export const OVERLAY_DRAWING_CLASS = 'image-overlay--drawing'
export const OVERLAY_UNDERLAY_CLASS = 'image-overlay--underlay'
export const OVERLAY_CONTRAST_CLASS = 'image-overlay--contrast'
export const PREVIEW_CLASS = 'image-preview'
export const PREVIEW_BG_CLASS = 'image-preview--bg'
export const PREVIEW_GRID_CLASS = 'image-preview--grid'
export const VERTICAL_GRID_CLASS = 'vertical-line'
export const HORIZONTAL_GRID_CLASS = 'horizontal-line'
export const SHOW_PATH_CLASS = 'shown-path'
export const SHOW_PATH_PREVIEW_CLASS = 'shown-path-preview'
export const PREVIEW_COMMENT_DRAWING_CLASS = 'preview-comment-drawing'

export const GALLERY_BG_DEFAULT = '#000000'

export const IMAGE_PREVIEW_MIN_HEIGHT = 200

export const DEFAULT_DRAWING_COLOR = '#DB5375'
export const DEFAULT_DRAWING_TYPE = 'ellipse'
export const DEFAULT_DRAWING_SVG_STROKE = '#F5F5F5'
export const DEFAULT_TEXT_COLOR = '#667085' // must correspond with CSS $light-color

export const DEFAULT_IS_EXPANDED = {
  imageInformation: true,
  contrast: false,
  background: true,
  grids: true,
  paths: true,
  layers: true,
  alphaChannel: true,
  history: false,
  commentSuggestion: true,
}

// must correspond with PHP constants
export const DEFAULT_IMAGE_SIZES = {
  width: 200,
  height: 300,
}

// must correspond with PHP constants
export const IMAGE_SIZES = {
  small: 1,
  medium: 2,
  extraLarge: 3,
  large: 900,
}

// must correspond with CSS constants
export const IMAGE_PREVIEWS_WIDTHS = {
  small: 200,
  medium: 600,
  large: 900,
}

// must correspond with PHP constants
export const IMAGE_TYPES = {
  input: 'in',
  output: 'out',
  compare: 'compare',
}

export const TEST_IMAGE_TYPE = 1

// must correspond with PHP constants
export const IMAGE_SORTINGS = {
  folderId: 'folder-id',
  folderFilename: 'folder-filename',
  id: 'id',
  filename: 'filename',
  formatFilename: 'format-filename',
  formatFolderFilename: 'format-folder-filename',
  product: 'product',
}

// not used yet
/* export const IMAGE_FILTERS = {
  all: 0,
  is_errors: 1,
  is_comments: 2,
  is_notifications: 3,
  is_confirmed: 4,
  is_missing: 5,
} */

// must correspond with PHP constants
export const COMMENT_TYPES = {
  client: 'client',
  qa: 'qa',
  qaClient: 'qa-client',
  sp: 'sp',
  public: 'public',
  qaPublic: 'qa-public',
  clientPublic: 'client-public',
  clientQa: 'client-qa',
}

// must correspond with PHP constants
export const COMMENT_STATUSES = {
  pending: 0,
  active: 100,
  rejected: 300,
  deleted: 900,
}

// must correspond with PHP constants
export const IMAGE_STATUSES = {
  ok: 1,
  internalReference: 10,
  backgroundCode: 20,
  colorationReference: 30,
  colorReference: 40,
  inputSpReference: 50,
  confirmed: 100,
  broken: 900,
}

// must correspond with PHP constants
export const ORDER_STATUSES = {
  new: 0,
  price_change: 1,
  waiting_payment: 2,
  choose_sp: 4,
  upload_to_sp: 5,
  in_process: 6,
  download_from_sp: 7,
  edited: 8,
  redo: 9,
  delivery: 10,
  test_image_uploaded: 22,
  client_qa_input: 70,
  client_redo: 80,
  public_redo: 81,
  client_public_redo: 82,
  client_approval_required: 85,
  public_approval_required: 86,
  completed: 100,
  on_hold: 900,
  canceled: 999,
}

// must correspond with PHP constants
export const IMAGE_ERROR_CODES = {
  widthError: 1,
  heightError: 2,
  dpiError: 3,
  colorSpaceError: 4,
  wrongFileName: 5,
  wrongFormat: 6,
  noBgColor: 7,
  wrongColorSampler: 8,
  metadataPhotoshopDescriptionError: 9,

  pathError: 20, // at least one path problem exist
  pathErrorPath1: 21,
  pathErrorPath2: 22,
  pathErrorPath3: 23,
  pathErrorPath4: 24,
  pathErrorPath5: 25,
  pathErrorPath6: 26,
  pathErrorPath7: 27,
  pathErrorPath8: 28,
  pathErrorPath9: 29,
  pathErrorPath10: 30,
  pathErrorPath11: 31,
  pathErrorPath12: 32,
  pathErrorPath13: 33,
  pathErrorPath14: 34,
  pathErrorPath15: 35,
  pathErrorPath16: 36,
  pathErrorPath17: 37,
  pathErrorPath18: 38,
  pathErrorPath19: 39,
  pathErrorPath20: 40,
  pathErrorMissing: 41,

  layerError: 50, // at least one layer problem exist
  layerErrorLayer1: 51,
  layerErrorLayer2: 52,
  layerErrorLayer3: 53,
  layerErrorLayer4: 54,
  layerErrorLayer5: 55,
  layerErrorLayer6: 56,
  layerErrorLayer7: 57,
  layerErrorLayer8: 58,
  layerErrorLayer9: 59,
  layerErrorLayer10: 60,
  layerErrorMissing: 61,

  containsAlphaChanel: 80,

  pathOutsideError: 230, // at least one path is outside problem exist
  pathOutsideErrorPath1: 231,
  pathOutsideErrorPath2: 232,
  pathOutsideErrorPath3: 233,
  pathOutsideErrorPath4: 234,
  pathOutsideErrorPath5: 235,
  pathOutsideErrorPath6: 236,
  pathOutsideErrorPath7: 237,
  pathOutsideErrorPath8: 238,
  pathOutsideErrorPath9: 239,
  pathOutsideErrorPath10: 240,
  pathOutsideErrorPath11: 241,
  pathOutsideErrorPath12: 242,
  pathOutsideErrorPath13: 243,
  pathOutsideErrorPath14: 244,
  pathOutsideErrorPath15: 245,
  pathOutsideErrorPath16: 246,
  pathOutsideErrorPath17: 247,
  pathOutsideErrorPath18: 248,
  pathOutsideErrorPath19: 249,
  pathOutsideErrorPath20: 250,

  inputImageWithoutOutput: 510,
  outputImageWithoutInput: 530,
}

export const IMAGES_PER_PAGE = 100
export const IMAGES_PER_PAGE_TIMEOUT = 1000

export const INTERVAL_60_SECONDS = 60000
export const INTERVAL_1_SECOND = 1000

export const IMAGE_THUMB_BORDER = 0 // must always correlate with CSS padding
export const IMAGE_OVERLAY_BORDER = 20

export const CONTROLS_DEFAULT_STATE = {
  imageSize: false,
  changeBackground: false,
  displaySettings: false,
  showGrids: false,
  showClippingPaths: false,
  showLayers: false,
  sortImages: false,
}

export const ALL_LAYERS_KEY = 'all_layers'
export const NO_UNDERLAY_KEY = 'no_underlay'

export const STROKE_WIDTH_RATIO = 500 // empirical number: smaller (thicker stroke) or bigger (thinner stroke)

export const PATH_WIDTH_MIN = 1
export const PATH_WIDTH_MAX = 20
export const PATH_DISPLAY_RATIO = 2

export const PREVIEW_NOT_FOUND_BG_COLOR = '#979797'

export const DRAWINGS_COLORS = [
  DEFAULT_DRAWING_COLOR, '#FFEB3B', '#0000FF', '#FF0000', '#00FFFF', '#FF00FF',
  '#FFA500', '#800080', '#008080', '#FFC0CB', '#BFFF00', '#8A2BE2',
  '#A52A2A', '#FFD700', '#DC143C', '#4B0082', '#808000', '#40E0D0',
  '#FF7F50', '#E6E6FA', '#7FFF00', '#800000', '#000080', '#DA70D6',
  '#FFBF00', '#00FF7F', '#FF1493', '#3CB371', '#1E90FF', '#FF6347',
  '#A0522D', '#DDA0DD', '#6A5ACD', '#228B22', '#4169E1', '#2E8B57',
  // '#FF69B4', '#FF8C00', '#9370DB', '#20B2AA',
]

export const PATH_COLORS = [
  '#81F378', '#FFEB3B', '#0000FF', '#FF0000', '#00FFFF', '#FF00FF',
  '#FFA500', '#800080', '#008080', '#FFC0CB', '#BFFF00', '#8A2BE2',
  '#A52A2A', '#FFD700', '#DC143C', '#4B0082', '#808000', '#40E0D0',
  '#FF7F50', '#E6E6FA', '#7FFF00', '#800000', '#000080', '#DA70D6',
  '#FFBF00', '#00FF7F', '#FF1493', '#3CB371', '#1E90FF', '#FF6347',
  '#A0522D', '#DDA0DD', '#6A5ACD', '#228B22', '#4169E1', '#2E8B57',
  // '#FF69B4', '#FF8C00', '#9370DB', '#20B2AA',
]

export const GRID_COLORS = [
  '#9AFF91', '#FFFF54', '#1919FF', '#FF1919', '#19FFFF', '#FF19FF',
  '#FFBE19', '#991999', '#199999', '#FFD9E4', '#D8FF19', '#A344FB',
  '#BE4343', '#FFF019', '#F52D55', '#64199B', '#999919', '#59F9E9',
  '#FF9869', '#FFFFFF', '#98FF19', '#991919', '#191999', '#F389EF',
  '#FFD819', '#19FF98', '#FF2DAC', '#55CC8A', '#37A9FF', '#FF7C60',
  '#B96B46', '#F6B9F6', '#8373E6', '#3BA43B', '#5A82FA', '#47A470',
  // '#FF82CD', '#FFA519', '#AC89F4', '#39CBC3',
]

export const PREVIEW_BG_COLORS = [
  '#9AFF91', '#FFFF54', '#1919FF', '#FF1919', '#19FFFF', '#FF19FF',
  '#FFBE19', '#991999', '#199999', '#FFD9E4', '#D8FF19', '#A344FB',
  // '#FF82CD', '#FFA519', '#AC89F4', '#39CBC3',
]

export const AVATAR_STYLE_LEFT = 6

export const INTERSECTION_OBSERVER_MARGIN = '500px'

export const ZOOM_SPEED = 500 // Increase or decrease value for zoom on mouse wheel
export const SCALE_TIMES = 5 // Maximal scaling
