import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'

import CommentOverlay from '../CommentOverlay'
import Comment from '../Comment'

import { ReactComponent as ExpandSvg } from '../../../../svg/expand.svg'

import './index.scss'

const CollapsibleCommentOverlay = ({
  image,
  refreshGallery,
  resetInterval,
  getResizeRatio,
  overlayImgDrawingRef,
  isSpaceRequired,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isCommentBlockCollapsed')) || false)

  useEffect(() => {
    localStorage.setItem('isCommentBlockCollapsed', isCollapsed)
  }, [isCollapsed])

  return (
    <div
      className="collapsible-comment"
      style={{
        right: 0,
        borderTopRightRadius: 0,
      }}
    >
      <div className="collapse-toggle" onClick={() => setIsCollapsed((prev) => !prev)}>
        <ExpandSvg style={{ rotate: isCollapsed ? '180deg' : 'none' }} />
      </div>
      {isCollapsed && (
        <div className="collapsible-comment__content">
          <div className="collapsible-comment__active-comments">
            <div className="collapsible-comment__active-comments-title">
              {Translation.active_comments}
            </div>

            {image?.image_comments.map((imageComment) => (
              <CommentOverlay
                key={imageComment.id}
                comment={imageComment}
                overlayImgDrawingRef={overlayImgDrawingRef}
              />
            ))}
          </div>
          <Comment
            refreshGallery={refreshGallery}
            resetInterval={resetInterval}
            getResizeRatio={getResizeRatio}
            overlayImgDrawingRef={overlayImgDrawingRef}
            isSpaceRequired={isSpaceRequired}
          />
        </div>
      )}
    </div>
  )
}

CollapsibleCommentOverlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  getResizeRatio: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  overlayImgDrawingRef: PropTypes.object,
  isSpaceRequired: PropTypes.bool,
}

CollapsibleCommentOverlay.defaultProps = {
  image: {},
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
  getResizeRatio: () => {
  },
  overlayImgDrawingRef: {},
  isSpaceRequired: false,
}

export default CollapsibleCommentOverlay
