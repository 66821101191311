import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { COMMENT_STATUSES, COMMENT_TYPES } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { appendShapes, getStrokeWidth, prepareComment } from '../../../../helpers/Utils'

import { ReactComponent as CommentedDrawingFreehandSvg } from '../../../../svg/commented_drawing_freehand.svg'
import { ReactComponent as CommentedTextSvg } from '../../../../svg/commented_text.svg'

import './index.scss'

const CommentOverlay = ({ comment, overlayImgDrawingRef }) => {
  const orderState = useStoreState((state) => ({
    imageShapes: state.order.imageShapes,
    shapesJson: state.order.shapesJson,
  }))

  const onMouseEnter = () => {
    if (comment.shapes && comment.shapes.length) {
      // eslint-disable-next-line no-param-reassign
      overlayImgDrawingRef.current.innerHTML = ''

      const strokeWidth = getStrokeWidth(
        overlayImgDrawingRef.current.getAttribute('width'),
        overlayImgDrawingRef.current.getAttribute('height'),
      )
      appendShapes(comment.shapes, strokeWidth, overlayImgDrawingRef.current)
    }
  }

  const onMouseLeave = () => {
    const strokeWidth = getStrokeWidth(
      overlayImgDrawingRef.current.getAttribute('width'),
      overlayImgDrawingRef.current.getAttribute('height'),
    )
    appendShapes(orderState.imageShapes, strokeWidth, overlayImgDrawingRef.current)
    appendShapes(orderState.shapesJson, strokeWidth, overlayImgDrawingRef.current)
  }

  return (
    <div
      className="overlay--comment__wrap"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="overlay--comment__icon overlay--comment__type">
        {comment.shapes
          ? <CommentedDrawingFreehandSvg stroke={comment.colour} />
          : <CommentedTextSvg className="right-2" />}
      </div>

      {(comment.type === COMMENT_TYPES.qaClient) && (
        <div className={`overlay--comment__icon overlay--comment__icon--client 
          ${parseInt(comment.status.toString(), 10) === COMMENT_STATUSES.active
          ? 'overlay--comment__icon--client__approved' : ''}`}
        >
          {Translation.client_short}
        </div>
      )}

      <div className="overlay--comment">
        {prepareComment(comment.comment)}
      </div>
    </div>
  )
}

CommentOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
  overlayImgDrawingRef: PropTypes.instanceOf(Object),
}

CommentOverlay.defaultProps = {
  comment: {},
  overlayImgDrawingRef: {},
}

export default CommentOverlay
