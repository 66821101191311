import React, { useRef, useState } from 'react'
import { useStoreState } from 'easy-peasy'

import { AVATAR_STYLE_LEFT, INTERVAL_1_SECOND } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import './index.scss'

const OverlayAvatars = () => {
  const orderState = useStoreState((state) => ({
    orderViewingUsers: state.order.orderViewingUsers,
  }))

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true

    setTimeout(() => {
      if (!isOpenedRef.current && isMouseOverRef.current) {
        setIsMouseOver(true)
      }
    }, INTERVAL_1_SECOND)
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false
    setIsMouseOver(false)
  }

  return (
    <div
      className={`overlay-avatars ${isMouseOver ? 'overlay-avatars__hover' : ''}`}
      onMouseEnter={onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}
    >
      {isMouseOver ? (
        <div className="header-users">
          <span className="bold">
            {orderState.orderViewingUsers.slice(0, -1).map((user) => (
              `${user.firstname} ${user.lastname}${user.is_client ? ' (CL)' : ''}`
            )).join(', ')}
          </span>
          {orderState.orderViewingUsers.length > 1 && (
            <span>
              {' '}
              {Translation.and}
              {' '}
            </span>
          )}
          <span className="bold">
            {orderState.orderViewingUsers.slice(-1).map((user) => (
              `${user.firstname} ${user.lastname} ${(user.is_client ? '(CL)' : '')}`
            ))}
          </span>
          <span>
            {' '}
            {Translation.also_viewing}
          </span>
        </div>
      ) : null}
      <div className="header--wraps header--avatars__wrap">
        {orderState.orderViewingUsers.map((avatar, index) => (
          <div
            key={avatar.id}
            className={`header--avatar ${avatar.is_client ? 'header--avatar__client' : ''}`}
            style={{
              zIndex: index,
              left: `${AVATAR_STYLE_LEFT * index}px`,
              backgroundColor: avatar.color,
            }}
          >
            {avatar.nickname}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OverlayAvatars
