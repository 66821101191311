import React from 'react'
import ReactDOM from 'react-dom/client'
import { StoreProvider } from 'easy-peasy'
import { RouterProvider } from 'react-router-dom'

import { Store } from './store/Store'
import { Router } from './router/Router'

import { enhanceAxiosInstance } from './helpers/Axios'

enhanceAxiosInstance(Store)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <StoreProvider store={Store}>
    <RouterProvider router={Router} />
  </StoreProvider>,
)
